import { useCallback, useEffect, useState } from "react";
import { Card, CardHeader, CardTitle, Container } from "reactstrap";

import Circle from 'components/markup/loading/Circle';

import moment from 'moment';

const HistoricalDailyLeadSources = ({ leadSources, day }) => {

    const [data, setData] = useState(null)

    const getColorOfChange = useCallback((num, isSeconds) => {
        if(num === 0 || num === '-') return '-'
        return num < 100 ? <b className="text-danger">{num}%</b> : <b className="text-success">+{num}%</b>
    }, [])

    useEffect(() => {

        const getPercentage = (num, total) => {
            if(!num || !total) return 0;

            return parseFloat(((num / total) * 100).toFixed(2))
        }

        const getChange = (num1, num2) => {
            return parseFloat((num1 - num2).toFixed(2))
        }

        const leadSourceCategories = leadSources.today;
        const sourceMetrics = []

        const now = Math.floor(new Date() / 1000)
        const startOfWeek = parseInt(moment().startOf('week').format('X'))
        const daysInWeek = Math.ceil((now - startOfWeek) / 86400)

        leadSourceCategories.forEach(q => {
            const sourceLastDayOfWeek = leadSources.lastDayOfWeek.find(d => d._id === q._id)
            const sourceCurrentWeek = leadSources.currentWeek.find(d => d._id === q._id)
            const sourceDays90 = leadSources.days90.find(d => d._id === q._id)

            const total = q.data.total;
            const totalLastDayOfWeek = sourceLastDayOfWeek.data.total;
            const totalCurrentWeek = sourceCurrentWeek.data.total;
            const total90Days = sourceDays90.data.total;

            const changeLastDayOfWeek = getChange(total, totalLastDayOfWeek)
            const changeCurrentWeek = getChange(total, totalCurrentWeek)
            const change90Days = getChange(total, total90Days)

            const averageCurrentWeek = parseFloat((totalCurrentWeek / daysInWeek).toFixed(2))
            const average90Days = parseFloat((total90Days / 90).toFixed(2))
           
            sourceMetrics.push({
                _id: q._id,
                source: q.source,
                total,
                totalLastDayOfWeek,
                totalCurrentWeek,
                total90Days,

                averageCurrentWeek,
                average90Days,

                changeLastDayOfWeek,
                changeCurrentWeek,
                change90Days,

                percentLastDayOfWeek: getPercentage(total, totalLastDayOfWeek),
                percentCurrentWeek: getPercentage(total, averageCurrentWeek),
                percent90Days: getPercentage(total, average90Days),

            })

        })

        setData({
            sourceMetrics
        })

    }, [leadSources])

    if(!data) return <Circle />

    return (

        <>

            <Container className="mt-5" fluid>

                <h2 className="text-uppercase">Lead Sources</h2>
                
                {data.sourceMetrics.map((q, i) => (
                    <Card key={q._id}  className="mb-0" style={i !== 0 ? {borderTop: 'none'} : {}}>
                        <CardHeader > 
                            <CardTitle className="mb-0">{q.source}</CardTitle>
                        </CardHeader>

                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Total</th>
                                    <th>Total Last {moment(day).format('dddd')}</th>
                                    <th className="text-right">AVG This Week</th>
                                    <th className="text-right">AVG Last 90 Days</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>{q.total}</td>
                                    <td>{q.totalLastDayOfWeek}</td>
                                    <td className="text-right">{q.averageCurrentWeek}</td>
                                    <td className="text-right">{q.average90Days}</td>
                                </tr>
                                <tr>
                                    {/* <td>{getPages}</td>
                                    <td> */}
                                    <td>-</td>
                                    <td>{getColorOfChange(q.percentLastDayOfWeek)}</td>
                                    <td className="text-right">{getColorOfChange(q.percentCurrentWeek)}</td>
                                    <td className="text-right">{getColorOfChange(q.percent90Days)}</td>
                                </tr>
                                {/* <tr>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>{getColorOfChange(q.slLastDayOfWeekChange)}</td>
                                    <td>{getColorOfChange(q.ttcLastDayOfWeekChange, true)}</td>
                                    <td>{getColorOfChange(q.slCurrentWeekChange)}</td>
                                    <td>{getColorOfChange(q.ttcCurrentWeekChange, true)}</td>
                                    <td>{getColorOfChange(q.slDays90Change)}</td>
                                    <td>{getColorOfChange(q.ttcDays90Change, true)}</td>
                                </tr> */}
                              
                            </tbody>
                            
                        </table>
                    </Card>
                ))}

            </Container>

        </>
    )

}

export default HistoricalDailyLeadSources