import { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Modal, FormGroup, Input, Row, Col } from "reactstrap";
import { useCallback } from 'react';

import Circle from 'components/markup/loading/Circle'
import StateInput from 'components/markup/inputs/States';
import ReactSelect from 'components/functional/inputs/ReactSelect'

import { toast } from 'react-toastify'

import api from 'api'

const ModalDivisionOverride = ({showModal, toggleModal, fetchData, courtCode, selected_division, _id}) => {

    const [baseState] = useState({
        division        : selected_division._id,
        court_code      : courtCode,
        name            : '',
        email           : '',
        phone           : '',
        address         : '',

        payment_link    : '',
        payment_address : '',
        hearing_address : '',
        website         : '',
    })

    const [trustee, setTrustee] = useState(null)
    const [err, setErr] = useState(null)

    const onInputChange = useCallback((field, val) => {
        const state = JSON.parse(JSON.stringify(trustee))
        state[field] = val;
        setTrustee(state)

    }, [trustee])

    const onSave = useCallback(async () => {
        if(!trustee.name) return toast.info(`A name must be added before saving a trustee`)

        let result;

        if(_id) {
            result = await api.modules.bk.trustees.update(_id, trustee)
        } else {
            result = await api.modules.bk.trustees.create({ ...trustee, division: selected_division._id })
        }

        if(!result.success) return toast.error(`Could not save trustee, please try again.`)

        toast.success(`Trustee Saved Successfully!`)
        toggleModal()
        fetchData()

    }, [_id, fetchData, selected_division._id, toggleModal, trustee])

    const fetchTrustee = useCallback(async () => {
        if(!_id) return;

        setTrustee(null)
        const result = await api.modules.bk.trustees.findById(_id);
        if(result.data) return setTrustee(result.data)

        return setErr(true)
    }, [_id])

    useEffect(() => {
        if(showModal) {
            fetchTrustee()
        } else {
            setTrustee(baseState)
        }
    }, [baseState, fetchTrustee, showModal])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">
                    {_id ? 'Edit Trustee' : 'Add Trustee'}
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            {err ? (
                <div className='modal-body'>
                    <div className='alert alert-danger mb-0'>
                        Could not find trustee, please refresh your page and try again.
                    </div>
                </div>
            ) : !trustee ? (
                <div className='modal-body'>
                    <Circle />
                </div>
            ) : (
                <>
                <div className="modal-body">

                    <h2 className='text-center mt-3'>Basic Info</h2>

                    <FormGroup>
                        <label className='form-control-label'>Trustee Name</label>
                        <Input 
                            type="text"
                            value={trustee.name}
                            onChange={e => onInputChange('name', e.target.value)}
                        />
                    </FormGroup>

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <label className='form-control-label'>Phone</label>
                                <Input 
                                    type="text"
                                    value={trustee.phone}
                                    onChange={e => onInputChange('phone', e.target.value)}
                                />
                            </FormGroup>
                        </Col>

                        <Col md={6}>
                            <FormGroup>
                                <label className='form-control-label'>Email</label>
                                <Input 
                                    type="text"
                                    value={trustee.email}
                                    onChange={e => onInputChange('email', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <label className='form-control-label'>Address (Enter as 1 Line)</label>
                        <Input 
                            type="text"
                            value={trustee.address}
                            onChange={e => onInputChange('address', e.target.value)}
                        />
                    </FormGroup>

                </div>

                <div className='modal-body bg-secondary border-top'>

                    <h2 className='text-center mt-3'>Division</h2>

                    <ReactSelect 
                        formGroup={true}
                        title="Associated District Division"
                        value={trustee.court_division}
                        onChange={(obj) => onInputChange('court_division', obj.value)}
                        options={[
                            { value: 'WDMI - Grand Rapids', label: 'WDMI - Grand Rapids' },
                            { value: 'WDMI - Kalamazoo', label: 'WDMI - Kalamazoo' },
                            { value: 'WDMI - Lansing', label: 'WDMI - Lansing' },
                            { value: 'WDMI - Marquette', label: 'WDMI - Marquette' },
                         
                            { value: 'EDMI - Detroit', label: 'EDMI - Detroit' },
                            { value: 'EDMI - Flint', label: 'EDMI - Flint' },
                            { value: 'EDMI - Bay City', label: 'EDMI - Bay City' },
                         
                            { value: 'NDOH - Cleveland', label: 'NDOH - Cleveland' },
                            { value: 'NDOH - Akron', label: 'NDOH - Akron' },
                            { value: 'NDOH - Toledo', label: 'NDOH - Toledo' },
                            { value: 'NDOH - Youngstown', label: 'NDOH - Youngstown' },
                            { value: 'NDOH - Canton', label: 'NDOH - Canton' },
                          
                            { value: 'SDOH - Columbus', label: 'SDOH - Columbus' },
                            { value: 'SDOH - Cincinnati', label: 'SDOH - Cincinnati' },
                            { value: 'SDOH - Dayton', label: 'SDOH - Dayton' },

                            // {
                            //     label: "WDMI (Western Distrct Of Michigan",
                            //     options: [
                            //         { value: 'WDMI - Grand Rapids', label: 'Grand Rapids' },
                            //         { value: 'WDMI - Kalamazoo', label: 'Kalamazoo' },
                            //         { value: 'WDMI - Lansing', label: 'Lansing' },
                            //         { value: 'WDMI - Marquette', label: 'Marquette' },
                            //     ]
                            // },
                            // {
                            //     label: "EDMI (Eastern Distrct Of Michigan",
                            //     options: [
                            //         { value: 'EDMI - Detroit', label: 'Detroit' },
                            //         { value: 'EDMI - Flint', label: 'Flint' },
                            //         { value: 'EDMI - Bay City', label: 'Bay City' },
                         
                            //     ]
                            // },
                            // {
                            //     label: "NDOH (Northern District Of Ohio",
                            //     options: [
                            //         { value: 'NDOH - Cleveland', label: 'Cleveland' },
                            //         { value: 'NDOH - Akron', label: 'Akron' },
                            //         { value: 'NDOH - Toledo', label: 'Toledo' },
                            //         { value: 'NDOH - Youngstown', label: 'Youngstown' },
                            //         { value: 'NDOH - Canton', label: 'Canton' },
                            //     ]
                            // },
                            // {
                            //     label: "SDOH (Southern District Of Ohio",
                            //     options: [
                            //         { value: 'SDOH - Columbus', label: 'Columbus' },
                            //         { value: 'SDOH - Cincinnati', label: 'Cincinnati' },
                            //         { value: 'SDOH - Dayton', label: 'Dayton' },
                            //     ]
                            // },

                        ]}
                    />
                </div>
               
                <div className='modal-body border-top'>

                    <h2 className='text-center mt-3'>Payments & Links</h2>

                    <FormGroup>
                        <label className='form-control-label'>Online Payment Address</label>
                        <Input 
                            type="text"
                            value={trustee.payment_link}
                            onChange={e => onInputChange('payment_link', e.target.value)}
                        />
                    </FormGroup>
                    
                    <FormGroup>
                        <label className='form-control-label'>341 Hearing Address (Zoom Link Or In Person Address)</label>
                        <Input 
                            type="text"
                            value={trustee.hearing_address}
                            onChange={e => onInputChange('hearing_address', e.target.value)}
                        />
                    </FormGroup>

                    <FormGroup>
                        <label className='form-control-label'>Mailing Payment Address</label>
                        <Input 
                            type="text"
                            value={trustee.payment_address}
                            onChange={e => onInputChange('payment_address', e.target.value)}
                        />
                    </FormGroup>
                   
                    <FormGroup>
                        <label className='form-control-label'>Website</label>
                        <Input 
                            type="text"
                            value={trustee.website}
                            onChange={e => onInputChange('website', e.target.value)}
                        />
                    </FormGroup>
                </div>

                <div className='modal-body bg-secondary border-top'>

                    <h2 className='text-center mt-3'>Associated Court</h2>

                    <FormGroup>
                        <label className='form-control-label'>Associated Court Name</label>
                        <Input 
                            type="text"
                            value={trustee.court_name}
                            onChange={e => onInputChange('court_name', e.target.value)}
                        />
                    </FormGroup>

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <label className='form-control-label'>Court Address Line 1</label>
                                <Input 
                                    type="text"
                                    value={trustee.court_address_line_1}
                                    onChange={e => onInputChange('court_address_line_1', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <label className='form-control-label'>Court Address Line 2</label>
                                <Input 
                                    type="text"
                                    value={trustee.court_address_line_2}
                                    onChange={e => onInputChange('court_address_line_2', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <label className='form-control-label'>Court City</label>
                                <Input 
                                    type="text"
                                    value={trustee.court_city}
                                    onChange={e => onInputChange('court_city', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <StateInput 
                                title="Court State"
                                placeholder=" "
                                value={trustee.court_state}
                                onChange={state => onInputChange('court_state', state)}
                                />
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <label className='form-control-label'>Court Zip</label>
                                <Input 
                                    type="text"
                                    value={trustee.court_postal_code}
                                    onChange={e => onInputChange('court_postal_code', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    

                </div>

                </>
            )}

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    <i className="fas fa-arrow-left mr-2" /> Close 
                </button>

                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" /> Save
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        selected_division: state.state.selected_division,
    };
};

export default connect(mapStateToProps, '')(ModalDivisionOverride);