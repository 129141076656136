import { Container } from 'reactstrap';
import { useEffect, useState} from 'react';

import BarLine from 'components/charts/BarLine';
import A from 'components/markup/links/A';

import moment from 'moment'

const HistoricalDailyCallTimes = ({callTimes, label, href}) => {

    const [data, setData] = useState(null)


    useEffect(() => {
        const now = Math.floor(new Date() / 1000)
        const startOfWeek = parseInt(moment().startOf('week').format('X'))
        const daysInWeek = Math.ceil((now - startOfWeek) / 86400)
      
        const today = callTimes.datasets.find(d => d.label === 'Today')
        const dayLastWeek = callTimes.datasets.find(d => d.label.includes('Last'))
        const thisWeek = callTimes.datasets.find(d => d.label === 'This Week')
        const last90Days = callTimes.datasets.find(d => d.label === 'Last 90 Days')

        thisWeek.data.forEach((d, i) => {
            thisWeek.data[i] = Math.round(d / daysInWeek)
        })
        
        last90Days.data.forEach((d, i) => {
            last90Days.data[i] = Math.round(d / 90)
        })

        setData({
            ...callTimes,
            datasets: [
                today,
                dayLastWeek,
                thisWeek,
                last90Days
            ]
        })
    
    }, [callTimes])


    if(!data) return <div />;

    return (
        <Container className="ful " fluid>
            <h2 className=" text-uppercase">
                {href ? <A href={href}>{label}</A> : label}
            </h2>
        
            {href ? (  
                <A href={href} className="cursor-pointer">
                    <BarLine 
                        type="line"
                        classNames={'border bg-white mb-4'}
                        labels={callTimes.labels}
                        datasets={callTimes.datasets}
                    />
                </A>

            ) : (
                <BarLine 
                    type="line"
                    classNames={'border bg-white mb-4'}
                    labels={callTimes.labels}
                    datasets={callTimes.datasets}
                />
            )}
    
        </Container>
    )
}

export default HistoricalDailyCallTimes;
