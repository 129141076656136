import hasPrivilege from 'utils/hasPrivilege';

import ViewPermission from 'components/markup/errors/ViewPermission';

import ByDay from '../pages/by_day';
import DocketSearch from '../pages/docket_search';
import OutstandingItemSearch from '../pages/outstanding_item_search';
import Hearings341 from '../pages/hearings_341';
import HearingsConfirmation from '../pages/hearings_confirmation';
import HearingsShowCause from '../pages/hearings_show_case';
import FilingFeeDeadlines from '../pages/filing_fee_deadlines';
import NewCaseEntriesSearch from '../pages/new_case_entries_search';
import Hearings from '../pages/hearings';
import ParsedActions from '../pages/parsed_actions';
import Trustees from '../pages/trustees';






const routes = () =>  [

   
  
    {
        path: "/outstanding_items",
        component: hasPrivilege('VIEW.MODULE.BK.MANAGEMENT_DASHBOARD') ? OutstandingItemSearch : ViewPermission,
    },
    {
        path: "/hearings",
        component: hasPrivilege('VIEW.MODULE.BK.MANAGEMENT_DASHBOARD') ? Hearings : ViewPermission,
    },
    {
        path: "/hearings/341",
        component: hasPrivilege('VIEW.MODULE.BK.MANAGEMENT_DASHBOARD') ? Hearings341 : ViewPermission,
    },
    {
        path: "/hearings/confirmation",
        component: hasPrivilege('VIEW.MODULE.BK.MANAGEMENT_DASHBOARD') ? HearingsConfirmation : ViewPermission,
    },
    {
        path: "/hearings/show_cause",
        component: hasPrivilege('VIEW.MODULE.BK.MANAGEMENT_DASHBOARD') ? HearingsShowCause : ViewPermission,
    },
    {
        path: "/filing_fee_deadlines",
        component: hasPrivilege('VIEW.MODULE.BK.MANAGEMENT_DASHBOARD') ? FilingFeeDeadlines : ViewPermission,
    },
    {
        path: "/docket_search",
        component: hasPrivilege('VIEW.MODULE.BK.MANAGEMENT_DASHBOARD') ? DocketSearch : ViewPermission,
    },
    {
        path: "/this_week",
        component: hasPrivilege('VIEW.MODULE.BK.MANAGEMENT_DASHBOARD') ? ByDay : ViewPermission,
    },
    {
        path: "/next_week",
        component: hasPrivilege('VIEW.MODULE.BK.MANAGEMENT_DASHBOARD') ? ByDay : ViewPermission,
    },
    {
        path: "/cases_filed",
        component: hasPrivilege('VIEW.MODULE.BK.MANAGEMENT_DASHBOARD') ? NewCaseEntriesSearch : ViewPermission,
    },
    {
        path: "/parsed_actions",
        component: hasPrivilege('VIEW.MODULE.BK.MANAGEMENT_DASHBOARD') ? ParsedActions : ViewPermission,
    },
    {
        path: "/trustees",
        component: hasPrivilege('VIEW.MODULE.BK.MANAGEMENT_DASHBOARD') ? Trustees : ViewPermission,
    },
    {
        path: "/",
        component: hasPrivilege('VIEW.MODULE.BK.MANAGEMENT_DASHBOARD') ? ByDay : ViewPermission,
    },
   
]

export default routes;
