import { useCallback, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import moment from 'moment';
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import A from "components/markup/links/A";

import Table from 'components/functional/tables/Standard';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import ApiError from "components/markup/layout/ApiError";
import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import ObjectFinder from 'components/system/Objects/Finder';

import api from 'api';

import Circle from 'components/markup/loading/Circle';

import Axios from 'axios';
import fileDownloader from 'js-file-download';
import keys from 'keys';

import { toast } from 'react-toastify';

import reactHTMLParser from 'html-react-parser';

const noData = { data: [], total_documents: 0 };

const MattersAll = ({selected_division, DOMAINS}) => {

    const [ courtCode, setCourtCode ] = useState('all');
    const [ result, setResult ] = useState('no_case');

    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState(noData)
    const [ hideTable, setHideTable ] = useState(false)
    const [ err, setErr ] = useState(false)

    const downloadBkDocument = useCallback(async (doc) => new Promise (async resolve => {
        try {

            const result = await Axios({
                method: 'get',
                url: keys.API_URL + `/v1/modules/bk/bk_documents/${doc.bk_document}/download`,
                responseType:'arraybuffer',
                headers: {
                    authorization: `Bearer ${keys.SYSTEM_API_KEY}`
                },
                withCredentials: true,
            })

            if(result.headers && result.headers['content-type'].includes('application/json')) {
                resolve(false);
                try {
                    var decodedString = String.fromCharCode.apply(null, new Uint8Array(result.data));
                    var body = JSON.parse(decodedString);
                    if(body.message && body.message.length) {
                        return toast.error(body.message[0] ? body.message[0] : `Something's Not Right, Please Try Again Later`)
                    }
                } catch(e) {
                    return toast.error(`Something went wrong downloading this document`)
                }
            }

            fileDownloader(result.data, doc.name) 
            resolve(true);
        } catch(e) {
            resolve(false);
            return toast.error(`Something went wrong downloading this court document`)
        }
    }), [])
 

    const onSetCourtCode = useCallback((val) => {
        setCourtCode(val)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])
  
    const onSetResult = useCallback((val) => {
        setResult(val)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])
   
    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};

        if(courtCode !== 'all') params.filter.court_code = courtCode
        params.filter.actions =  { $exists: true, $ne: [] }


        if(result === 'test') {
            params.filter['actions.result'] = 'TEST MODE - NO ACTION TAKEN' 
        } else if(result === 'errors') {
            params.filter['actions.result'] = 'INTERNAL ERROR'
        } else if(result === 'no_case') {
            params.filter.matter = null
        } else if(result === 'success') {
            // params.filter.matter = { exists: true }
            params.filter['actions.result'] = { $nin: ['TEST MODE - NO ACTION TAKEN', 'INTERNAL ERROR'] }
        }

        const query = await api.modules.bk.supervisor.docketSearch(selected_division._id, { ...params, isCSV })

        if(query.data) return resolve(query.data)
        setErr(query.message)
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {
            dataField: "timestamp_unix",
            text: 'Case Info',
            headerStyle: { width: 150 },
            tdStyle: { verticalAlign: 'top' },
            formatter: (cell, row) => (
                row.name === 'no data' ? <div style={{minHeight: 150}}>No Data To Show</div> : 
                <div>
                    <div>
                        {row.matter ? (
                            <div className="text-uppercase font-weight-bold text-dark mb-3">
                                <A href={`${DOMAINS.APP}/matters/${row.matter}?showModule=bk&moduleTab=Overview`}><ObjectFinder collection="matters" _id={row.matter} /></A>
                            </div>
                        ) : (
                            <div className="text-uppercase font-weight-bold text-dark mb-3">
                                <b className="text-underline text-danger">CASE NOT FOUND</b>
                            </div>
                        )}
                    </div>
                    <div>{row.case_number}</div>
                    <div>{row.court_code}</div>
                    <div className="mt-3">{row.date_filed}</div>
                </div>
            )
        },
        {
            dataField: "docket_no",
            text: 'Entry #',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : 
                <div>
                    {reactHTMLParser(row.docket_text)}
                    {row.annotations && row.annotations.length ? (
                        <div className='border mt-3 px-3 py-3 rounded' style={{background: '#eaeaea'}}>
                            <h5 className='text-whit mb-3'>
                                <span className='border rounded bg-warning text-white px-4 py-2' style={{width: 165, display: 'inline-block'}}>
                                    <i className="fa-solid fa-calendar" /> Important Dates
                                </span>
                            </h5>
                            {row.annotations.map((d, i) => (
                                <div key={i}>
                                    <div className='text-uppercase font-weight-bold'>{d.name}</div>
                                    <p className='text-sm mb-0'>
                                        {d.date_formatted ? moment(d.date_formatted).format('MM/DD/YYYY h:mm A') : (
                                            <span className='text-warning'>{d.date} - Check Timezone With Docket Entry</span>
                                        )}
                                        
                                    </p>
                                </div>
                            ))}
                        </div>
                    ) : ''}
                
                    {row.documents && row.documents.length ? (
                        <div className='border mt-3 px-3 py-3 rounded' style={{background: '#eaeaea'}}>

                            <h5 className='text-whit mb-3'>
                                <span className='border rounded bg-purple text-white px-4 py-2' style={{width: 165, display: 'inline-block'}}>
                                    <i className="fa-solid fa-file" /> Associated Documents
                                </span>
                            </h5>
                            {row.documents.map((d, i) => (
                                <div key={i}>
                                    <span className='cursor-pointer text-uppercase text-dark' onClick={() => downloadBkDocument(d)}>
                                        {d.name}
                                    </span>
                                </div>
                            ))}
                        </div>
                    ) : ''}
                    
                    {row.actions && row.actions.length ? (
                        <div className='border mt-3 px-3 py-3 rounded' style={{background: '#eaeaea'}}>
                            <h5 className='text-whit'>
                                <span className='border rounded bg-success text-white px-4 py-2' style={{width: 165, display: 'inline-block'}}>
                                    <i className="fa-solid fa-bolt-lightning" /> Background Actions
                                </span>
                            </h5>
                            {row.actions.map((d, i) => (
                                <div key={i}>

                                    <div className='mt-3'>
                                        <div className='text-uppercase text-dark'>{d.name}</div>
                                        <div>
                                            <span style={{width: 100, display: 'inline-block'}}>Value Found:</span>
                                            {d.type === 'date' ? moment(d.value).format('MM/DD/YYYY h:mm A') : d.value}
                                        </div>

                                        <div>
                                            <span style={{width: 100, display: 'inline-block'}}>Result:</span>
                                            {d.result && d.result.includes('Set For:') ? (
                                                <span>Set For: {moment(d.result.split('Set For: ')[1]).format('MM/DD/YYYY h:mm A')} </span>
                                            ) : d.result}
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    ) : ''}
                </div>
            )
        },
       
    ]

    if(err) return <ApiError className="py-4 mx-5" err={err} />

    const title = 'Parsed Actions'

    return (

        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            leftColSize={5}
        />

        <Card>
            <CardBody>

                <Row>
                    <Col md={6}>
                        <ReactSelect
                            title={"Select District"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => onSetCourtCode(obj.value)}
                            options={[
                                { value: 'all', label: 'All Districts' },
                                { value: 'miebke', label: 'Eastern District Of Michigan' },
                                { value: 'miwbke', label: 'Western District Of Michigan' },
                                { value: 'ohnbke', label: 'Northern District Of Ohio' },
                                { value: 'ohsbke', label: 'Southern District Of Ohio' },
                            ]}
                            value={courtCode}
                        />    
                    </Col>
                    <Col md={6}>
                        <ReactSelect
                            title={"Select Action Result"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => onSetResult(obj.value)}
                            options={[
                                { value: 'no_case', label: 'Case Not Found' },
                                { value: 'test', label: 'Test Mode' },
                                { value: 'errors', label: 'Errors' },
                                { value: 'success', label: 'Successful' },
                                { value: 'all', label: 'All' },
                            ]}
                            value={result}
                        />    
                    </Col>
                </Row>

            </CardBody>
        </Card>

        {hideTable ? <Circle /> : (
            <Container fluid>
        
                <Card className="card-color card-primary table-fixed table-no-stripes">
                    <Table
                        tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                        placeholder="Search Docket Text..."
                        query={query}
                        columns={columns}
                        data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                        totalDocuments={tableData.total_documents}
                        sizePerPage={sizePerPage}
                        onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                    />    
                </Card>

            </Container>
            )}
        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	    viewing_user: state.auth.viewing_user,
        DOMAINS: state.load.DOMAINS,
	};
};

export default connect(mapStateToProps, '')(MattersAll);
