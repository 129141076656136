import { useEffect, useState, memo } from 'react';

const TableStandardBody2 = ({ columns, data }) => {

    const [body, setBody] = useState([])

    useEffect(() => {
        const _body = data.length ? data.map(d => {
            return (
                <tr key={d._id} >
                    { columns.map((c, i) => (
                        <td key={i} style={c.tdStyle ? c.tdStyle : {}}>{c.formatter ? c.formatter(d, d) : d[c.dataField]}</td>
                    )) }
                </tr>
            )
        }) : (
            <tr>
                {columns.map((c, i) => (
                    i === 0 ? <td key={i}>No Data</td> : <td key={i}></td>
                ))}
            </tr>
        )
        setBody(_body)
    }, [columns, data])


    return (
        <tbody>
            {body}
        </tbody>
    )

}

export default memo(TableStandardBody2)