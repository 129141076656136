import { useCallback, useEffect, useState } from "react";
import { Card, CardHeader, CardTitle, Container, Row, Col } from "reactstrap";

import Circle from 'components/markup/loading/Circle';
import A from 'components/markup/links/A';

import moment from 'moment';

const HistoricalDailyCallQueues = ({ callQueues, day }) => {

    const [data, setData] = useState(null)

    const getColorOfChange = useCallback((num, isSeconds) => {
        if(num === 0 || num === '-') return '-'
        return num > 0 ? <b className="text-success">+{num}{isSeconds ? ' Seconds' : '%'}</b> : <b className="text-danger">-{num}{isSeconds ? ' Seconds' : '%'}</b>
    }, [])

    useEffect(() => {

        const getPercentage = (num, total) => {
            if(!num || !total) return 0;

            return parseFloat(((num / total) * 100).toFixed(2))
        }
        const getAverage = (num, total) => {
            if(!num || !total) return 0;

            return parseFloat(((num / total)).toFixed(2))
        }
        const getChange = (num1, num2) => {
            return parseFloat((num1 - num2).toFixed(2))
        }

        const queues = callQueues.today;
        const queueMetrics = []

        queues.forEach(q => {
            const queueLastDayOfWeek = callQueues.lastDayOfWeek.find(d => d._id === q._id)
            const queueCurrentWeek = callQueues.currentWeek.find(d => d._id === q._id)
            const queueDays90 = callQueues.days90.find(d => d._id === q._id)

            const sl = getPercentage(q.data.service_level, q.data.total)
            const ttc = getAverage(q.data.total_time_till_connected, q.data.total)

            const slLastDayOfWeek = getPercentage(queueLastDayOfWeek.data.service_level, queueLastDayOfWeek.data.total)
            const ttcLastDayOfWeek = getAverage(queueLastDayOfWeek.data.total_time_till_connected, queueLastDayOfWeek.data.total)
            const slLastDayOfWeekChange = getChange(sl, slLastDayOfWeek)
            const ttcLastDayOfWeekChange = getChange(ttcLastDayOfWeek, ttc)
           
            const slCurrentWeek = getPercentage(queueCurrentWeek.data.service_level, queueCurrentWeek.data.total)
            const ttcCurrentWeek = getAverage(queueCurrentWeek.data.total_time_till_connected, queueCurrentWeek.data.total)
            const slCurrentWeekChange = getChange(sl, slCurrentWeek)
            const ttcCurrentWeekChange = getChange(ttcCurrentWeek, ttc)
           
            const slDays90 = getPercentage(queueDays90.data.service_level, queueDays90.data.total)
            const ttcDays90 = getAverage(queueDays90.data.total_time_till_connected, queueDays90.data.total)
            const slDays90Change = getChange(sl, slDays90)
            const ttcDays90Change = getChange(ttcDays90, ttc)

            queueMetrics.push({
                _id: q._id,
                queue: q.queue,
                sl,
                ttc,
                slLastDayOfWeek,
                ttcLastDayOfWeek,
                slLastDayOfWeekChange,
                ttcLastDayOfWeekChange,
                slCurrentWeek,
                ttcCurrentWeek,
                slCurrentWeekChange,
                ttcCurrentWeekChange,
                slDays90,
                ttcDays90,
                slDays90Change,
                ttcDays90Change,
            })

        })

        setData({
            queueMetrics
        })

    }, [callQueues])

    if(!data) return <Circle />

    return (

        <>

            <Container className="ful " fluid>
                <h2 className="text-uppercase">Call Queues</h2>
                
                {data.queueMetrics.map((q, i) => {
                    const link = `/communication/queue/${q._id}`
                    return  (
                        <Card key={q._id} className="mb-0" style={i !== 0 ? {borderTop: 'none'} : {}}>
                            <CardHeader > 
                                <Row>
                                    <Col md={6}>
                                        <CardTitle className="mb-0">
                                            <A href={link}>{q.queue}</A>
                                        </CardTitle>
                                    </Col>
                                    <Col md={6} className="text-right">
                                        <A href={link}><i className="fas fa-link text-info cursor-pointer" /></A>
                                    </Col>
                                </Row>
                            </CardHeader>
    
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Service<br />Level</th>
                                        <th>AVG<br/>Connection Time</th>
                                        <th>SL<br/>Last {moment(day).format('dddd')}</th>
                                        <th>ACT<br/>Last {moment(day).format('dddd')}</th>
                                        <th>SL<br/>This Week</th>
                                        <th>ACT<br/>This Week</th>
                                        <th>SL<br/>Last 90 Days</th>
                                        <th>ACT<br/>Last 90 Days</th>
                                    </tr>
                                </thead>
    
                                <tbody>
                                    <tr>
                                        <td>{q.sl}%</td>
                                        <td>{q.ttc} Seconds</td>
                                        <td>{q.slLastDayOfWeek}%</td>
                                        <td>{q.ttcLastDayOfWeek} Seconds</td>
                                        <td>{q.slCurrentWeek}%</td>
                                        <td>{q.ttcCurrentWeek} Seconds</td>
                                        <td>{q.slDays90}%</td>
                                        <td>{q.ttcDays90} Seconds</td>
                                    </tr>
                                    <tr>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>{getColorOfChange(q.slLastDayOfWeekChange)}</td>
                                        <td>{getColorOfChange(q.ttcLastDayOfWeekChange, true)}</td>
                                        <td>{getColorOfChange(q.slCurrentWeekChange)}</td>
                                        <td>{getColorOfChange(q.ttcCurrentWeekChange, true)}</td>
                                        <td>{getColorOfChange(q.slDays90Change)}</td>
                                        <td>{getColorOfChange(q.ttcDays90Change, true)}</td>
                                    </tr>
                                  
                                </tbody>
                                
                            </table>
                        </Card>
                    )
                })}

            </Container>

        </>
    )

}

export default HistoricalDailyCallQueues