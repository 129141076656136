import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { toast } from 'react-toastify';
import { Container, Row, Col, Card, CardBody, CardFooter, CardTitle, CardHeader } from "reactstrap";

import Circle from 'components/markup/loading/Circle';
import HeaderNavigation from 'components/markup/layout/HeaderNavigation';

import api from 'api';
import moment from 'moment-timezone';

import DatePicker from 'react-datepicker'

import CallQueues from './CallQueues'
import StepMoves from './StepMoves'
import LeadSources from './LeadSources'
import CallHandleTimes from './CallHandleTimes'
import CallTimes from './CallTimes'

const HistoricalDaily = ({selected_division}) => {

    const [data, setData] = useState(null)
    const [day, setDay] = useState(new Date())

    const fetchData = useCallback(async () => {

        const startTime = parseInt(moment(day).tz(selected_division.timezone).startOf('day').format('X'))

        const filter = { 
            start: startTime,
            dayOfWeek: moment(day).format('dddd')
        }

        setData(null);

        const result = await api._supervisor.historical.daily(selected_division._id, filter )
        if(!result.data) return toast.error(`Something went wrong, please refresh your page and try again.`)

        setData(result.data)
    }, [day, selected_division._id, selected_division.timezone])

    useEffect(() => {
        fetchData();

        // document.body.classList.add('bg-secondary');
        // return () => document.body.classList.remove('bg-secondary');
    }, [fetchData])

    if(!data) return <Circle className="py-6" />

    return (

        <>

            <Helmet>
                <title>{`Historical Reports - Daily`}</title>
                <meta name="description" content="Historical Reports - Daily" />
            </Helmet>

            <HeaderNavigation 
                title="Historical Reports"
                description="Daily"
                leftColSize={4}
                actionComponent={(
                    <Row>
                        <Col md={9}></Col>

                        <Col md={3}>
                            <p className="text-sm mb-0">Select Day</p>
                            <DatePicker 
                                selected={day} 
                                onChange={(date) => setDay(date)} 
                            />
                        </Col>
                    
                    </Row>
                )}
            />



            <Container className="ful mt--4 pt-4" fluid>
                
                {data ? (
                    <div className="z-d">

                        <div className="border">
                            <div className="px-4 py-3 bg-white border-bottom text-center">
                                <h2 className="mb-0 mt-0 display-4 "><i className="fas fa-headset mr-2 text-info" /> Call Center Metrics</h2>
                            </div>

                            <div className="p-4 pb-5 bg-secondary">
                                <Row>
                                    <Col md={6}>
                                        <CallTimes 
                                            href={`/calls?direction=inbound`}
                                            callTimes={data.incomingCallTimes} 
                                            label={<span><i className="fas fa-arrow-down text-success mr-2" /> Incoming Call Times</span>} 
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <CallTimes
                                            href={`/calls?direction=outbound`}
                                            callTimes={data.outgoingCallTimes} 
                                            label={<span><i className="fas fa-arrow-up text-warning mr-2" /> Outgoing Call Times</span>} 
                                        />
                                    </Col>
                                </Row>
                                <CallQueues callQueues={data.callQueues} day={day} />
                                <CallHandleTimes handleTimes={data.handleTimes} day={day} />
                            </div>
                        </div>

                        <div className="border">
                            <div className="px-4 py-3 bg-white border-bottom text-center">
                                <h2 className="mb-0 display-4 text-center "><i className="fas fa-gavel mr-2 text-info" /> Matter Progress</h2>
                            </div>

                            <div className="p-4 pb-5 bg-secondary">
                                <StepMoves stepMoves={data.stepMoves} day={day} />
                            </div>
                        </div>
                       
                        <div className="border">
                            <div className="px-4 py-3 bg-white border-bottom text-center">
                                <h2 className="mb-0 display-4 text-center "><i className="fas fa-line-chart mr-2 text-info" /> Marketing</h2>
                            </div>

                            <div className="p-4 pb-5 bg-secondary">
                                <LeadSources leadSources={data.leadSources} day={day} />
                            </div>
                        </div>

                    </div>
                ) : <Circle />}
            
            </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    device: state.device,
	    socket: state.socket,
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(HistoricalDaily);
