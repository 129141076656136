import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";

import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from "reactstrap";

import ModalToggler from 'components/functional/modals/Toggler';
import A from 'components/markup/links/A';
import Circle from 'components/markup/loading/Circle';
import ObjectFinder from 'components/system/Objects/Finder';

import api from 'api';

import { toast } from 'react-toastify';

import formatText from 'utils/formatText';

import moment from 'moment';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

///////////////
// this should be an exact copy of the admin module function in the query creator file
// at /views/analytics/pages/_QueryCreator/rangeSelection
const getRangeByValue = (valStart, valEnd) => {
    const day = 86400;

    let end = parseInt(moment().endOf('day').format('X'))
    let start;

    // if we have no value we are searching for either a set cookie value OR 
    // to return the start and end of today
    if(!valStart) {
        valStart = cookies.get('archk-query-time-group')
        if(!valStart) {
            start = parseInt(moment().startOf('day').format('X'))
            end = parseInt(moment().endOf('day').format('X'))
            return { start, end }
        }
       
    }

    switch (valStart) {
        case "Today": {
            start = end - (day * 1) + 1;  
            cookies.set('archk-query-time-group', 'Today', { path: '/' });
            break;
        }
        case "Yesterday": {
            start = end - (day * 2)  + 1;
            end = end - (day * 1);
            cookies.set('archk-query-time-group', 'Yesterday', { path: '/' });
            break;
        }
        case "This Week": {
            start = parseInt(moment().startOf('week').format('X'))
            end = parseInt(moment().endOf('week').format('X'))
            cookies.set('archk-query-time-group', 'This Week', { path: '/' });
            break;
        }
        case "Last Week": {
            start = parseInt(moment().subtract(1, 'week').startOf('week').format('X'))
            end = parseInt(moment().subtract(1, 'week').endOf('week').format('X'))
            cookies.set('archk-query-time-group', 'Last Week', { path: '/' });
            break;
        }
        case "This Month": {
            start = parseInt(moment().startOf('month').format('X'))
            end = parseInt(moment().endOf('month').format('X'))
            cookies.set('archk-query-time-group', 'This Month', { path: '/' });
            break;
        }
        case "Last Month": {
            start = parseInt(moment().subtract(1, 'month').startOf('month').format('X'))
            end = parseInt(moment().subtract(1, 'month').endOf('month').format('X'))
            cookies.set('archk-query-time-group', 'Last Month', { path: '/' });
            break;
        }
        case "This Quarter": {
            start = parseInt(moment().startOf('quarter').format('X'))
            end = parseInt(moment().endOf('quarter').format('X'))
            cookies.set('archk-query-time-group', 'This Quarter', { path: '/' });
            break;
        }
        case "Last Quarter": {
            start = parseInt(moment().subtract(1, 'quarter').startOf('quarter').format('X'))
            end = parseInt(moment().subtract(1, 'quarter').endOf('quarter').format('X'))
            cookies.set('archk-query-time-group', 'Last Quarter', { path: '/' });
            break;
        }
        case "Last 30 Days": 
            start =  parseInt(moment().subtract(30, 'day').startOf('day').format('X'));  
            cookies.set('archk-query-time-group', 'Last 30 Days', { path: '/' });
            break;
        case "Last 60 Days": 
            start =  parseInt(moment().subtract(60, 'day').startOf('day').format('X'));  
            cookies.set('archk-query-time-group', 'Last 60 Days', { path: '/' });
            break;
        case "Last 90 Days": 
            start =  parseInt(moment().subtract(90, 'day').startOf('day').format('X'));  
            cookies.set('archk-query-time-group', 'Last 90 Days', { path: '/' });
            break;
        case "Last 180 Days": 
            start =  parseInt(moment().subtract(180, 'day').startOf('day').format('X'));  
            cookies.set('archk-query-time-group', 'Last 180 Days', { path: '/' });
            break;
        case "Last 365 Days": 
            start =  parseInt(moment().subtract(365, 'day').startOf('day').format('X'));  
            cookies.set('archk-query-time-group', 'Last 365 Days', { path: '/' });
            break;

        default: 
            if(valStart && typeof valStart === 'string' && valStart.includes('-')) {
                const split = valStart.split('-');
                start = parseInt(split[0]);
                end = parseInt(split[1]);
            } else {
                if(valStart) start = parseInt(moment(valStart).format('X'))
                if(valEnd) end = parseInt(moment(valEnd).format('X'))
            }
        ;
    }

    return { start, end }

}

const ModalItems = ({DOMAINS, selected_division, showModal, toggleModal, title, filter, collection}) => {

    const [data, setData] = useState(null)

    const fetchData = useCallback(async () => {
        const result = await api._supervisor.todaysActivityItems(selected_division._id, { filter, collection })
        if(!result.success) return toast.info(`Could not load items, please try again.`)

        setData(result.data)
    }, [collection, filter, selected_division._id])

    useEffect(() => {
        if(showModal) {
            fetchData()
        } else {
            setData(null)
        }
    }, [fetchData, showModal])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">{title}</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                {!data ? <Circle /> : collection === 'matters' ? (
                    <div className="table-responsive">
                        <table className="table border">
                            <thead>
                                <th>Name</th>
                                <th>Current Step</th>
                                <th>Last Moved</th>
                                <th>Users</th>
                                <th></th>
                            </thead>

                            <tbody>
                                {data.length ? (
                                    data.map(d => {

                                        const roles = d.roles ? d.roles.filter(u => u.user) : []
                                        const link = `${DOMAINS.APP}/matters/${d.id}`

                                        return (
                                            <tr key={d._id}>
                                                <td><A href={link}>{d.name}</A></td>
                                                <td>{d.current_step_name}</td>
                                                <td>{moment.unix(d.current_step_start).format('MM/DD/YYYY : h:mm A')}</td>
                                                <td>
                                                    {roles.map((r, i) => (
                                                        <span key={i}>{i !== 0 ? ', ' : ''} <ObjectFinder collection="users" _id={r.user} /></span>
                                                    ))}
                                                </td>
                                                <td className="text-right">
                                                    <A href={link} className="btn btn-sm btn-outline-info"><i className="fas fa-link mr-2" /> Open</A>
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td>No results found</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : collection === 'calls' ? (
                    <div className="table-responsive">
                        <table className="table border">
                            <thead>
                                <th>Contact</th>
                                <th>Start</th>
                                <th>End</th>
                                <th>Status</th>
                                <th></th>
                            </thead>

                            <tbody>
                                {data.length ? (
                                    data.map(d => {
                                        const link = `/calls/${d._id}`

                                        return (
                                            <tr key={d._id}>
                                                <td><A href={link}><ObjectFinder collection="contacts" _id={d.contact} /></A></td>
                                                <td>{moment.unix(d.start).format('h:mm A')}</td>
                                                <td>{moment.unix(d.end).format('h:mm A')}</td>
                                                <td className="text-capitalize">{d.status ? d.abandon_type === 'short' ? 'Short Abandon' : d.status.replace('_', ' ') : ''}</td>
                                                <td className="text-right">
                                                    <A href={link} className="btn btn-sm btn-outline-info"><i className="fas fa-link mr-2" /> Open</A>
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td>No results found</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : collection === 'bk_credit_reports' ? (
                    <div className="table-responsive">
                        <table className="table border">
                            <thead>
                                <th>Contact</th>
                                <th>Pulled By</th>
                                <th>Type</th>
                                <th>Time</th>
                                <th></th>
                            </thead>

                            <tbody>
                                {data.length ? (
                                    data.map(d => {
                                        const link = `${DOMAINS.APP}/matters/${d.matter}`

                                        return (
                                            <tr key={d._id}>
                                                <td><A href={link}><ObjectFinder collection="contacts" _id={d.contact} /></A></td>
                                                <td><ObjectFinder collection="users" _id={d.created_by} /></td>
                                                <td>{d.type}</td>
                                                <td>{moment.unix(d.created_at).format('MM/DD/YYYY h:mm A')}</td>
                                                <td className="text-right">
                                                    <A href={link} className="btn btn-sm btn-outline-info"><i className="fas fa-link mr-2" /> Open</A>
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td>No results found</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : collection === 'contacts' ? (
                    <div className="table-responsive">
                        <table className="table border">
                            <thead>
                                <th>Contact</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Created</th>
                                <th></th>
                            </thead>

                            <tbody>
                                {data.length ? (
                                    data.map(d => {
                                        const link = `${DOMAINS.APP}/contacts/${d.id}`

                                        return (
                                            <tr key={d._id}>
                                                <td><A href={link}><ObjectFinder collection="contacts" _id={d.contact} /></A></td>
                                                <td>{d.email ? d.email : ''}</td>
                                                <td>{d.phone ? formatText(d.phone).phone() : ''}</td>
                                                <td>{moment.unix(d.created_at).format('MM/DD/YYYY h:mm A')}</td>
                                                <td className="text-right">
                                                    <A href={link} className="btn btn-sm btn-outline-info"><i className="fas fa-link mr-2" /> Open</A>
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td>No results found</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : collection === 'documents' || collection === 'signing_documents' ? (
                    <div className="table-responsive">
                        <table className="table border">
                            <thead>
                                <th>Name</th>
                                <th>Matter</th>
                                <th>Uploaded At</th>
                                <th></th>
                            </thead>

                            <tbody>
                                {data.length ? (
                                    data.map(d => {
                                        const link = `${DOMAINS.APP}/matters/${d.matter}`

                                        return (
                                            <tr key={d._id}>
                                                <td><A href={link}>{d.name}</A></td>
                                                <td><A href={link}><ObjectFinder collection="matters" _id={d.matter} /></A></td>
                                                <td>{moment.unix(d.created_at).format('MM/DD/YYYY h:mm A')}</td>
                                                <td className="text-right">
                                                    <A href={link} className="btn btn-sm btn-outline-info"><i className="fas fa-link mr-2" /> Open</A>
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td>No results found</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : collection === 'events' ? (
                    <div className="table-responsive">
                        <table className="table border">
                            <thead>
                                <th>Name</th>
                                <th>Matter</th>
                                <th>Originator</th>
                                <th>Start</th>
                                <th></th>
                            </thead>

                            <tbody>
                                {data.length ? (
                                    data.map(d => {
                                        const link = `${DOMAINS.APP}/matters/${d.matter}`

                                        return (
                                            <tr key={d._id}>
                                                <td><A href={link}>{d.name}</A></td>
                                                <td><A href={link}><ObjectFinder collection="matters" _id={d.matter} /></A></td>
                                                <td><A href={link}><ObjectFinder collection="users" _id={d.originator} /></A></td>
                                                <td>{moment.unix(d.unix_start).format('MM/DD/YYYY h:mm A')}</td>
                                                <td className="text-right">
                                                    <A href={link} className="btn btn-sm btn-outline-info"><i className="fas fa-link mr-2" /> Open</A>
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td>No results found</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : collection === 'outstanding_items' ? (
                    <div className="table-responsive">
                        <table className="table border">
                            <thead>
                                <th>Name</th>
                                <th>Matter</th>
                                <th>Assigned To</th>
                                <th>Due Date</th>
                                <th></th>
                            </thead>

                            <tbody>
                                {data.length ? (
                                    data.map(d => {
                                        const link = `${DOMAINS.APP}/matters/${d.matter}?tab=Items`

                                        return (
                                            <tr key={d._id}>
                                                <td><A href={link}>{d.name}</A></td>
                                                <td><A href={link}><ObjectFinder collection="matters" _id={d.matter} /></A></td>
                                                <td>
                                                    <A href={link}>
                                                        {d.assigned_to && d.assigned_to.length ? d.assigned_to.map((e, i) => (
                                                            <span key={i}>{i === 0 ? '' : ', '}<ObjectFinder collection="users" _id={e} /></span>
                                                        )) : ''}    
                                                    </A>
                                                </td>
                                                <td>{moment.unix(d.due_date).format('MM/DD/YYYY h:mm A')}</td>
                                                <td className="text-right">
                                                    <A href={link} className="btn btn-sm btn-outline-info"><i className="fas fa-link mr-2" /> Open</A>
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td>No results found</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : ''}
            </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
            </div>

        </Modal>

    )
}

//////////////////////////////////////////////////////////////////////////////////////////
// hard to understand, this function mimics the admin dashboards analyze page to get
// a number to display on the daily activity page. it hyper links there
// there "filter" property is really an analytic_reports object and not a tru filter like elsewhere
//////////////////////////////////////////////////////////////////////////////////////////
const CustomReport = ({DOMAINS, title, filter, value }) => {

    const [num, setNum] = useState(null)

    const getColor = useCallback((option) => {
        if(num === null) return ''
        const success = parseInt(option.success);
        const info = parseInt(option.info);
        const warning = parseInt(option.warning);
        const danger = parseInt(option.danger);
    
        if(success && !Number.isNaN(success)) {
            if(num > success) return 'success'
        }
        
        if(info && !Number.isNaN(info)) {
            if(num > info) return 'info'
        }
        
        if(warning && !Number.isNaN(warning)) {
            if(num > warning) return 'warning'
        }
        
        if(danger && !Number.isNaN(danger)) {
            if(num < danger) return 'danger'
        }
    
        return ''
    
    }, [num])

    const color = getColor(value)
    let style = { background: 'none' }
    if(color) style.borderBottom = 'solid 1px white'

    const fetchData = useCallback(async () => {
        let _filter = JSON.parse(JSON.stringify(filter.filter));

        Object.keys(_filter).forEach(k => {
            if(Array.isArray(_filter[k])) _filter[k] = _filter[k].map(o => o.value)
        })

        const data = await api.analyze.matters({
            filter: _filter, 
            sort: filter.sort, 
            range: getRangeByValue(filter.group), 
            limit: filter.limit, 
            skip: 0,
            isCSV: false
        })

        setNum(data.data ? data.data.total_documents : 0)
    }, [filter.filter, filter.group, filter.limit, filter.sort])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <Col md={3}>
            <A href={`${DOMAINS.ADMIN}/analytics/matters?report=${filter._id}`}>
                <Card className={`cursor-pointer bg-gradient-${color}`}>
                    <CardHeader style={style}>
                        <CardTitle className={`mb-0 text-center ${color ? 'text-white' : ''}`}>{title}</CardTitle>
                    </CardHeader>

                    <CardBody className='text-center py-3'>
                        {num === null ? (
                            <div className="py-3 my-1"> <Circle /> </div>
                        ) : (
                            <p className={`mb-0 display-2 ${color ? 'text-white' : ''}`}>{num}</p>
                        )}
                    </CardBody>
                </Card>
            </A>
        </Col>
    )
}

const Box = ({ DOMAINS, selected_division, title, value, color, collection, filter }) => {

    

    if(collection === 'analytic_reports') return (
        <CustomReport 
            DOMAINS={DOMAINS}
            selected_division={selected_division}
            title={title}
            value={value}
            collection={collection} 
            filter={filter} 
        />
    )

    let style = { background: 'none' }
    if(color) style.borderBottom = 'solid 1px white'

    return (
        <Col md={3}>
            <ModalToggler component={ModalItems} title={title} collection={collection} filter={filter} DOMAINS={DOMAINS} selected_division={selected_division}>
                <Card className={`cursor-pointer bg-gradient-${color}`}>
                    <CardHeader style={style}>
                        <CardTitle className={`mb-0 text-center ${color ? 'text-white' : ''}`}>{title}</CardTitle>
                    </CardHeader>
        
                    <CardBody className='text-center py-3'>
        
                        <p className={`mb-0 display-2 ${color ? 'text-white' : ''}`}>{value}</p>
                    </CardBody>
                </Card>
            </ModalToggler>

        </Col>
    )
}

const DashboardOverview = ({ DOMAINS, selected_division, data }) => (
    <Row>
        {data.map((d, i) => (
            <Box 
                key={i}
                selected_division={selected_division}
                DOMAINS={DOMAINS}
                title={d.title}
                value={d.value}
                color={d.color}
                collection={d.collection}
                filter={d.filter}
            />
        ))}       
    </Row>
)


const mapStateToProps = state => {
	return {
        selected_division: state.state.selected_division,
        DOMAINS: state.load.DOMAINS,
	};
};

export default connect(mapStateToProps, '')(DashboardOverview);


