import { useCallback, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import moment from 'moment';
import { Badge, Card, CardBody, Col, Container, Row } from "reactstrap";

import Table from 'components/functional/tables/Standard';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import TableIcons from 'components/markup/icons/TableIcons';
import ApiError from "components/markup/layout/ApiError";
import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import A from 'components/markup/links/A';
import ObjectFinder from 'components/system/Objects/Finder';
import SearchCollections from "components/system/Search/Collections";

import api from 'api';

import Circle from 'components/markup/loading/Circle';
import DatePicker from 'react-datepicker'

import formatText from 'utils/formatText'

import Cookies from 'universal-cookie';

const noData = { data: [], total_documents: 0 };

const MattersAll = ({selected_division, DOMAINS}) => {
    const [ now ] = useState(Math.floor(new Date() / 1000));

    // { value: 'deadlines', label: 'Only Deadlines' },
    // { value: 'attorney_approvals', label: 'Only Attorney Approvals' },
    // { value: 'hearings', label: 'Only Hearings' },
    
    const [ finished, setFinished ] = useState(false);
    const [ type, setType ] = useState('all');
    const [ checklist, setChecklist ] = useState('all');
    const [ priority, setPriority ] = useState('all');
    const [ day, setDay ] = useState(moment().endOf('day').subtract(30, 'days').toDate())
    const [ end, setEnd ] = useState(moment().endOf('day').toDate())
    const [ user, setUser ] = useState('')

    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState(noData)
    const [ hideTable, setHideTable ] = useState(false)
    const [ err, setErr ] = useState(false)

    const onSetFinished = useCallback((val) => {
        setFinished(val)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])

    const onSetDay = useCallback((val) => {
        setDay(val)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])
    
    const onSetEnd = useCallback((val) => {
        setEnd(val)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])

    const onSetUser = useCallback((val) => {
        setUser(val)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])
   
    const onSetType = useCallback((val) => {
        setType(val)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])
   
    const onSetChecklist = useCallback((val) => {
        setChecklist(val)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])
    const onSetPriority = useCallback((val) => {
        setPriority(val)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])
   
    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};

        params.filter.module = 'bk'

        const exclusions = [];

        // const [ excludeTasks, setExcludeTasks ] = useState(cookies.get('archk-bk-exclude-tasks') === 'true' ? true : false);
        // const [ excludeFollowUps, setExcludeFollowUps ] = useState(cookies.get('archk-bk-exclude-follow-ups') === 'true' ? true : false);
        // const [ excludeDeadlines, setExcludeDeadlines ] = useState(cookies.get('archk-bk-exclude-deadlines') === 'true' ? true : false);
        // const [ excludeAttorneyApprovals, setExcludeAttorneyApprovals ] = useState(cookies.get('archk-bk-exclude-attorney-approvals') === 'true' ? true : false);
        // const [ excludeHearings, setExcludeHearings ] = useState(cookies.get('archk-bk-exclude-hearings') === 'true' ? true : false);
        // if(excludeTasks) params.filter.is_deadline = true
        // if(excludeDeadlines) params.filter.is_deadline = true

        if(type === 'tasks') {
            params.filter.is_deadline = false;
        } else if(type === 'deadlines') {
            params.filter.is_deadline = true;
            // params.filter.category = { $ne: 'bk_hearing' };
        } else if(type === 'hearings') {
            params.filter.is_deadline = true;
            params.filter.category = 'bk_hearing';
        } else if(type === 'attorney_approvals') {
            params.filter.category = 'bk_attorney_approval';
        } else if(type === 'follow_ups') {
            params.filter.category = 'bk_follow_up';
        }

        if(checklist === 'yes') params.filter.checklist_percent = 100
        if(checklist === 'no') params.filter.checklist_percent =  { $ne: 100 };

        if(user) params.filter.assigned_to = { $in: [user] }

        if(priority !== 'all') params.filter.priority = priority

        if(day && end) params.filter.due_date = { $gte: parseInt(moment(day).format('X')), $lte: parseInt(moment(end).format('X')) }

        if(finished === false) params.filter.finished_at = 0
        if(finished === true) params.filter.finished_at = { $gt: 0 }

        params.filter.hidden = { $ne: true }


        const query = await api.modules.bk.supervisor.outstandingItemSearch(selected_division._id, { ...params, isCSV })

        if(query.data) return resolve(query.data)
        setErr(query.message)
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {
            dataField: "name",
            text: 'Task',
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )

                const overdue = row.due_date && row.due_date < now && !row.finished_at ? true : false;

                return (
                    <div> 
                        <A 
                            href={`${DOMAINS.APP}/matters/${row.matter}?showModule=bk`} 
                            className={`font-weight-bold ${overdue ? 'text-danger text-uppercase' : 'text-dark'}`}
                        >
                            {row.is_deadline ? <i className="fas fa-clock text-info mr-2" /> : ''}
                            {row.category === 'bk_hearing' ? <i className="fas fa-calendar text-warning mr-2" /> : ''}
                            {row.name}

                            {row.finished_at ? (
                                <div className={`${overdue ? 'text-danger font-weight-bold text-uppercase' : 'text-muted'}`}>
                                    <b className="text-success"><i className="fas fa-check mr-2" /></b> {moment.unix(row.finished_at).format('MMM Do, h:mm A')}
                                    {' - '}
                                    <ObjectFinder collection="users" _id={row.finished_by} />
                                </div>
                            ) : row.due_date ? (
                                <div className={`${overdue ? 'text-danger font-weight-bold text-uppercase' : 'text-muted'}`}>
                                    {moment.unix(row.due_date).format('MMM Do, h:mm A')}
                                </div>
                            ) : ''}
                        </A>


                        
                    </div>
                )
            },
        },
        {
            dataField: "last_comment",
            text: 'Comment',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : !row.last_comment ? '' : (
                    <span>
                        {formatText(row.last_comment).stripHTML(100)}
                        {' - '}
                        <b className="text-info">{row.last_comment_by ? <ObjectFinder collection="users" _id={row.last_comment_by}/> : 'System'}</b>
                    </span>
                )
            )
        },
        {
            dataField: "matter",
            text: 'Matter',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : 
                <div>
                    <div><A href={`/matters/${row.matter}`} className="text-dark font-weight-bold"><ObjectFinder collection="matters" _id={row.matter} /></A></div>
                </div>
            )
        },
        {
            dataField: "checklist",
            text: 'Checklist',
            headerStyle: { textAlign: 'right' },
            formatter: (cell, row) => {
                if(row.name === 'no data' || !row.checklist) return '';

                const finished = row.checklist.filter(r => r.finished).length
                if(finished === row.checklist.length) return (
                    <div className="text-center"><i className="fas fa-check-circle text-success" /></div>
                )

                return (
                    <div className="text-center">
                        {finished} / {row.checklist.length}
                    </div>
                )
            }
        },
        {
            dataField: "assigned_to",
            text: 'Assigned To',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : !row.assigned_to ? '' :
                row.assigned_to.map((c, i) => (
                    <span>{i !== 0 ? ', ' : ''}<ObjectFinder collection="users" _id={c} /></span>
                ))
            )
        },
        {
            dataField: "priority",
            text: 'Priority',
            formatter: (cell, row) => {
                if(row.name === 'no data') return ''
                if(row.priority === 1) return <Badge color="danger">Highest</Badge>
                if(row.priority === 2) return <Badge color="warning">High</Badge>
                if(row.priority === 3) return <Badge color="info">Default</Badge>
                if(row.priority === 4) return <Badge color="purple">Low</Badge>
                if(row.priority === 5) return <Badge color="success">Lowest</Badge>
            }
        },
        
        
        // {
        //     dataField: "due_date",
        //     text: 'due',
        //     formatter: (cell, row) => (
        //         row.name === 'no data' ? '' : (
        //             <div>
        //                 <div>{!row.due_date ? '-' : row.due_date < Math.floor(new Date() / 1000) ? <span className="text-danger">{moment.unix(row.due_date).format('MM/DD/YYYY h:mm A')}</span> : moment.unix(row.due_date).format('MM/DD/YYYY h:mm A')}</div>
        //             </div>
        //         )
        //     )
        // },
        
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right', width: 100},
            text: '',
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        <TableIcons
                            icons={[
                                { 
                                    icon: 'fas fa-external-link',
                                    color: 'success', 
                                    wrapper: A, 
                                    href: `${DOMAINS.APP}/matters/${row.matter}?showModule=bk`,
                                },
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(err) return <ApiError className="py-4 mx-5" err={err} />

    const title = 'Bankruptcy Tasks'

    return (

        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            leftColSize={5}

        />

        <Card className="mt--4">
            <CardBody>

                <Row>
                
                    <Col md={3}>
                        <p className="text-sm mb-0">Search By User</p>
                        <SearchCollections
                            collection={'users'}
                            hideTitle={true}
                            placeholder="Search..."
                            value={user}
                            onChange={(obj) => onSetUser(obj.value)}
                            filter={{
                                division: selected_division._id,
                            }}
                        /> 
                    </Col>
                    <Col md={3}>
                        <ReactSelect
                            title={"Finished"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => onSetFinished(obj.value)}
                            options={[
                                { value: 'all', label: <span className="text-white">-</span> },
                                { value: true, label: 'Finished Tasks' },
                                { value: false, label: 'Unfinished Tasks' },
                            ]}
                            value={finished}
                        />    
                    </Col>

                    <Col md={3}>
                        <p className="text-sm mb-0">Due Date Start</p>
                        <DatePicker 
                            selected={day} 
                            onChange={(date) => onSetDay(date)} 
                        />
                    </Col>
                

                    <Col md={3}>
                        <p className="text-sm mb-0">Due Date End</p>
                        <DatePicker 
                            selected={end} 
                            onChange={(date) => onSetEnd(date)} 
                        />
                    </Col>
                
                </Row>

                <Row>
                    <Col md={4}>
                        <ReactSelect
                            title={"Task Type"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => onSetType(obj.value)}
                            options={[
                                { value: 'all', label: <span className="text-white">-</span> },
                                { value: 'tasks', label: 'Only Tasks' },
                                { value: 'follow_ups', label: 'Only Follow Ups' },
                                { value: 'deadlines', label: 'Only Deadlines' },
                                { value: 'attorney_approvals', label: 'Only Attorney Approvals' },
                                { value: 'hearings', label: 'Only Hearings' },
                            ]}
                            value={type}
                        />    
                    </Col>

                    <Col md={4}>
                        <ReactSelect
                            title={"Checklist Finished"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => onSetChecklist(obj.value)}
                            options={[
                                { value: 'all', label: <span className="text-white">-</span> },
                                { value: 'yes', label: 'Yes' },
                                { value: 'no', label: 'No' },
                            ]}
                            value={checklist}
                        />    
                    </Col>
                    <Col md={4}>
                        <ReactSelect
                            title={"Priority"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => onSetPriority(obj.value)}
                            options={[
                                { value: 'all', label: <span className="text-white">-</span> },
                                { value: 1, label: 'Priority 1 (Highest)', },
                                { value: 2, label: 'Priority 2 (High)'  },
                                { value: 3, label: 'Priority 3 (Default)'  },
                                { value: 4, label: 'Priority 4 (Low)'  },
                                { value: 5, label: 'Priority 5 (Lowest)'  },
                            ]}
                            value={priority}
                        />    
                    </Col>

                </Row>

                {/* <Row>
                    <Col>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id="archk-bk-exclude-tasks"
                                type="checkbox"
                                checked={excludeTasks}
                                onChange={() => onSetExcludeTasks(!excludeTasks)}
                                />
                            <label className="custom-control-label" htmlFor="archk-bk-exclude-tasks">
                                Exclude Tasks
                            </label>
                        </div>
                    </Col>
                    
                    <Col>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id="archk-bk-exclude-follow-ups"
                                type="checkbox"
                                checked={excludeFollowUps}
                                onChange={() => onSetExcludeFollowUps(!excludeFollowUps)}
                                />
                            <label className="custom-control-label" htmlFor="archk-bk-exclude-follow-ups">
                                Exclude Follow Ups
                            </label>
                        </div>
                    </Col>
                    <Col>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id="archk-bk-exclude-deadlines"
                                type="checkbox"
                                checked={excludeDeadlines}
                                onChange={() => onSetExcludeDeadlines(!excludeDeadlines)}
                                />
                            <label className="custom-control-label" htmlFor="archk-bk-exclude-deadlines">
                                Exclude Deadlines
                            </label>
                        </div>
                    </Col>

                    <Col>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id="archk-bk-exclude-attorney-approvals"
                                type="checkbox"
                                checked={excludeAttorneyApprovals}
                                onChange={() => onSetExcludeAttorneyApprovals(!excludeAttorneyApprovals)}
                                />
                            <label className="custom-control-label" htmlFor="archk-bk-exclude-attorney-approvals">
                                Exclude Attorney Approvals
                            </label>
                        </div>
                    </Col>

                    <Col>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id="archk-bk-exclude-hearings"
                                type="checkbox"
                                checked={excludeHearings}
                                onChange={() => onSetExcludeHearings(!excludeHearings)}
                                />
                            <label className="custom-control-label" htmlFor="archk-bk-exclude-hearings">
                                Exclude Hearings
                            </label>
                        </div>
                    </Col>
                </Row> */}

            </CardBody>
        </Card>

        {hideTable ? <Circle /> : (
            <Container fluid>
        
                <Card className="card-color card-primary table-fixed table-no-stripes">
                    <Table
                        tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                        placeholder="Search By Item Name..."
                        query={query}
                        columns={columns}
                        data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                        totalDocuments={tableData.total_documents}
                        sizePerPage={sizePerPage}
                        onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                    />    
                </Card>

            </Container>
            )}
        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
        DOMAINS: state.load.DOMAINS,
	};
};

export default connect(mapStateToProps, '')(MattersAll);
