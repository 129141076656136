import { useCallback, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import moment from 'moment';
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import Table from 'components/functional/tables/Standard';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import ApiError from "components/markup/layout/ApiError";
import HeaderNavigation from 'components/markup/layout/HeaderNavigation';

import api from 'api';

import Circle from 'components/markup/loading/Circle';

import Axios from 'axios';
import fileDownloader from 'js-file-download';
import keys from 'keys';

import { toast } from 'react-toastify';

import reactHTMLParser from 'html-react-parser';

const noData = { data: [], total_documents: 0 };

const MattersAll = ({selected_division, viewing_user}) => {

    const [ courtCode, setCourtCode ] = useState('all');

    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState(noData)
    const [ hideTable, setHideTable ] = useState(false)
    const [ err, setErr ] = useState(false)

    const downloadBkDocument = useCallback(async (doc) => new Promise (async resolve => {
        try {

            const result = await Axios({
                method: 'get',
                url: keys.API_URL + `/v1/modules/bk/bk_documents/${doc.bk_document}/download`,
                responseType:'arraybuffer',
                headers: {
                    authorization: `Bearer ${keys.SYSTEM_API_KEY}`
                },
                withCredentials: true,
            })

            if(result.headers && result.headers['content-type'].includes('application/json')) {
                resolve(false);
                try {
                    var decodedString = String.fromCharCode.apply(null, new Uint8Array(result.data));
                    var body = JSON.parse(decodedString);
                    if(body.message && body.message.length) {
                        return toast.error(body.message[0] ? body.message[0] : `Something's Not Right, Please Try Again Later`)
                    }
                } catch(e) {
                    return toast.error(`Something went wrong downloading this document`)
                }
            }

            fileDownloader(result.data, doc.name) 
            resolve(true);
        } catch(e) {
            resolve(false);
            return toast.error(`Something went wrong downloading this court document`)
        }
    }), [])
 

    const onSetCourtCode = useCallback((val) => {
        setCourtCode(val)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])
   
    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};

        // params.filter.module = 'bk'

        // if(type === 'tasks') {
        //     params.filter.is_deadline = false;
        // } else if(type === 'deadlines') {
        //     params.filter.is_deadline = true;
        //     // params.filter.category = { $ne: 'bk_hearing' };
        // } else if(type === 'hearings') {
        //     params.filter.is_deadline = true;
        //     params.filter.category = 'bk_hearing';
        // } else if(type === 'attorney_approvals') {
        //     params.filter.category = 'bk_attorney_approval';
        // } else if(type === 'follow_ups') {
        //     params.filter.category = 'bk_follow_up';
        // }

        // if(checklist === 'yes') params.filter.checklist_percent = 100
        // if(checklist === 'no') params.filter.checklist_percent =  { $ne: 100 };

        if(courtCode !== 'all') params.filter.court_code = courtCode

        // // { value: 'all', label: <span className="text-white">-</span> },
        // // { value: 'tasks', label: 'Only Tasks' },
        // // { value: 'deadlines', label: 'Only Deadlines' },
        // // { value: 'hearings', label: 'Only Hearings' },

        const query = await api.modules.bk.supervisor.docketSearch(selected_division._id, { ...params, isCSV })

        if(query.data) return resolve(query.data)
        setErr(query.message)
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {
            dataField: "timestamp_unix",
            text: 'Date',
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )

                return (

                       <div>
                    {row.date_filed}
                </div>
                )
            },
        },
        {
            dataField: "case_number",
            text: 'Case Info',
            headerStyle: { width: 150 },
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : 
                <div>
                    <div>{row.case_number}</div>
                    <div>{row.court_code}</div>
                </div>
            )
        },
        {
            dataField: "docket_no",
            text: 'Entry #',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : 
                <div>
                    {row.docket_no}
                </div>
            )
        },
        {
            dataField: "docket_no",
            text: 'Entry #',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : 
                <div>
                   {reactHTMLParser(row.docket_text)}
                        {row.annotations && row.annotations.length ? (
                            <div className='border mt-3 p-3 bg-secondary'>
                                <h5>Important Dates</h5>
                                {row.annotations.map((d, i) => (
                                    <div key={i}>
                                        {d.name}
                                        <p className='text-sm mb-0'>
                                            {d.date_formatted ? moment(d.date_formatted).format('MM/DD/YYYY h:mm A') : (
                                                <span className='text-warning'>{d.date} - Check Timezone With Docket Entry</span>
                                            )}
                                            
                                        </p>
                                    </div>
                                ))}
                            </div>
                        ) : ''}
                    
                        {row.documents && row.documents.length ? (
                            <div className='border mt-3 p-3 bg-secondary'>
                                <h5>Associated Documents</h5>
                                {row.documents.map((d, i) => (
                                    <div key={i}>
                                        <span className='cursor-pointer' onClick={() => downloadBkDocument(d)}>
                                            {d.name}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        ) : ''}
                </div>
            )
        },
       
    ]

    if(err) return <ApiError className="py-4 mx-5" err={err} />

    const title = 'Docket Search'

    return (

        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            leftColSize={5}

        />

        <Card>
            <CardBody>

                <Row>
                    <Col md={12}>
                        <ReactSelect
                            title={"Select District"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => onSetCourtCode(obj.value)}
                            options={[
                                { value: 'all', label: 'All Districts' },
                                { value: 'miebke', label: 'Eastern District Of Michigan' },
                                { value: 'miwbke', label: 'Western District Of Michigan' },
                                { value: 'ohnbke', label: 'Northern District Of Ohio' },
                                { value: 'ohsbke', label: 'Southern District Of Ohio' },
                            ]}
                            value={courtCode}
                        />    
                    </Col>
                </Row>

            </CardBody>
        </Card>

        {hideTable ? <Circle /> : (
            <Container fluid>
        
                <Card className="card-color card-primary table-fixed table-no-stripes">
                    <Table
                        tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                        placeholder="Search Docket Text..."
                        query={query}
                        columns={columns}
                        data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                        totalDocuments={tableData.total_documents}
                        sizePerPage={sizePerPage}
                        onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                    />    
                </Card>

            </Container>
            )}
        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(MattersAll);
