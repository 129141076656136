/*

TODAYS ACTIVITY

Pipeline Categories

Itemized List Of Leads
Step Progress
Tasks
Events

Recreate the sheet of leads, offers, hires, etc.

Payments
Recurrings

Documents
Texts
Emails
Support Tickets
Voicemails

Queue Service Levels

MONTH TO DATE
Payments
Recurrings
Pipeline Categories
Won/Refer Out/Hired, etc.
Step Progress
AVG support ticket resolution time
QUEUE Metrics (think zoom)

QUEUE & COMMUNICATION
Service Level
Total Inbound / Outbounds
Abandoned, Completed, etc.
- Average Wrap Up Time
- Average Handle Time
- Average Hold

PIPELINE BREAKDOWN
Pipeline Categories
Disposition Categories
Estimated Value
Total Value

REVENUE PROJECTIONS
Pipeline Aggregates for stages
Projected Payments
New Recurrings
Decline Rate
Success Rate
Charge back Rate
Avg Payments
** SUM OF CALLS FOR BILLING

USER METRICS
Docs Uploaded
Tasks Completed
Step Progress Achieved
Support Tickets Handled
Calls Handled
Notes Made
Queue Metrics
- avg call time, wrap up time, etc.

USER AVAILABILITY
List of all times a user is available for a given day

STEP PROGRESS
Aggregated by category
Fields Completed
Docs Uploaded
Tasks Finished

MATTERS OVERDUE
List of all currently overdue matters that need their steps moved

MATTER ALERTS
List of all alerts created on a matter

*** Mark incoming communication as read when a piece of outgoing communication goes out
*** text messages should be in the notes tab of the feed 
*/
import React from 'react';
import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { toast } from 'react-toastify';
import { Container, Row, Col, Card, CardHeader, CardTitle } from "reactstrap";

import Circle from 'components/markup/loading/Circle';
import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import { formatCurrency } from 'utils/currency';


import api from 'api';
import moment from 'moment';

import DatePicker from 'react-datepicker'



const SupervisorMonthToDate = ({selected_division}) => {

    const [data, setData] = useState(null)
    const [day, setDay] = useState(new Date())

    const fetchData = useCallback(async () => {
        setData(null);

        const start               = parseInt(moment(day).startOf('month').format('X'));
        const end                 = parseInt(moment(day).endOf('month').format('X'))
        const comparison_start    = parseInt(moment(day).subtract(1, 'month').startOf('month').format('X'));
        const comparison_end      = parseInt(moment(day).subtract(1, 'month').endOf('month').format('X'));

        const filter = { start, end, comparison_start, comparison_end }

        const result = await api._supervisor.monthToDate(selected_division._id, filter )
        if(!result.data) return toast.error(`Something went wrong, please refresh your page and try again.`)
        setData(result.data)
    }, [selected_division._id, day])

    const createComparison = (title, prop, prop2, type) => {

        const current     = prop2 ? data.main[prop][prop2] : data.main[prop];
        const comparison = prop2 ? data.comparison[prop][prop2] : data.comparison[prop];
        
        const percent = current && comparison ? (current / comparison) * 100 : '-'
        const negative = percent !== '-' && percent < 100

        return (
            <tr>
                <td>{title}</td>
                <td>{type === 'currency' && current ? formatCurrency(current) : current}</td>
                <td>{type === 'currency' && comparison ? formatCurrency(comparison) : comparison}</td>
                <td className={`text-right ${negative ? 'text-danger' : 'text-success'}`}>
                    {percent === '-' ? '-' : (
                        <span>{negative ? '-' : '+'}{percent.toFixed(2)}%</span>
                    )}
                </td>
            </tr>
        )
    }

    // type is categories or dispositions
    const createPipelineCard = (type) => (
        <Card>
            <CardHeader>
                <CardTitle className="mb-0">Pipeline {type === 'categories' ? 'Categories' : 'Dispositions'}</CardTitle>
                <p className='text-sm mb-0'>This card shows all the matters that were moved to the following {type === 'categories' ? 'categories' : 'dispositions'} that ARE STILL IN the same category as of the time this report was loaded. This is not a historical report but one that shows current matter status by this month and last.</p>
            </CardHeader>

            <div className="table-responsive">
                <table className="table border table-bordered">
                    <thead>
                        <tr>
                            <th>Workflow</th>
                            <th>{type === 'categories' ? 'Category' : 'Disposition'}</th>
                            <th>Moved This Month</th>
                            <th>Moved Last Month</th>
                            <th className="text-right">Change</th>
                            <th>Estimated $ This Month</th>
                            <th>Estimated $ Last Month</th>
                            <th className="text-right">Change</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                        {data.main[type].map(w => (
                            <React.Fragment key={w.workflow._id}>
                                <tr>
                                    <td className='font-weight-bold text-dark text-uppercase'>{w.workflow.name}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>

                                {w[type].map(c => {
                                    const comparisonWorkflow = data.comparison[type].find(cc => cc.workflow._id === w.workflow._id);
                                    const comparisonCategory = comparisonWorkflow[type].find(cc => cc._id === c._id)

                                    const currentTotal     = c.total;
                                    const comparisonTotal = comparisonCategory.total;
                                    
                                    const percentTotal = currentTotal && comparisonTotal ? (currentTotal / comparisonTotal) * 100 : '-'
                                    const negativeTotal = percentTotal !== '-' && percentTotal < 100
                                    
                                    const currentValue     = c.value;
                                    const comparisonValue = comparisonCategory.value;
                                    
                                    const percentValue = currentValue && comparisonValue ? (currentValue / comparisonValue) * 100 : '-'
                                    const negativeValue = percentValue !== '-' && percentValue < 100

                                    return (
                                        <tr key={c._id}>
                                            <td></td>
                                            <td>{c.name}</td>
                                            <td>{c.total}</td>
                                            <td>{comparisonCategory.total}</td>
                                            <td className={`text-right ${negativeTotal ? 'text-danger' : 'text-success'}`}>
                                                {percentTotal === '-' ? '-' : (
                                                    <span>{negativeTotal ? '-' : '+'}{percentTotal.toFixed(2)}%</span>
                                                )}
                                            </td>
                                            <td>{formatCurrency(c.value)}</td>
                                            <td>{formatCurrency(comparisonCategory.value)}</td>
                                            <td className={`text-right ${negativeValue ? 'text-danger' : 'text-success'}`}>
                                                {percentValue === '-' ? '-' : (
                                                    <span>{negativeValue ? '-' : '+'}{percentValue.toFixed(2)}%</span>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </Card>
    )

    useEffect(() => {
        fetchData();

        document.body.classList.add('bg-secondary');
        return () => document.body.classList.remove('bg-secondary');
    }, [fetchData])

    if(!data) return <Circle className="py-6" />

    return (

        <>

            <Helmet>
                <title>{`Month To Date`}</title>
                <meta name="description" content="Month To Date" />
            </Helmet>

            <HeaderNavigation 
                title="Month To Date"
                description="Comparison View"
                leftColSize={4}
                actionComponent={(
                    <Row>
                        <Col md={6}></Col>

                        <Col md={6}>
                            <p className="text-sm mb-0">Select Month</p>
                            <DatePicker 
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                selected={day} 
                                onChange={(date) => setDay(date)} 
                            />
                        </Col>
                    
                    </Row>
                )}
            />

            <Container className="ful mt--4 pt-4" fluid>
        
                {createPipelineCard('categories')}
                {createPipelineCard('dispositions')}

                <Row>
                    <Col lg={6}>
                        
                        <Card>
                            <CardHeader>
                                <CardTitle className="mb-0">Phone Calls</CardTitle>
                            </CardHeader>

                            <div className="table-responsive">
                                <table className="table border table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Property</th>
                                            <th>This Month</th>
                                            <th>Last Month</th>
                                            <th className="text-right">Change</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {createComparison('Total Calls', 'calls', 'total')}
                                        {createComparison('Outbound Calls', 'calls', 'outbound')}
                                        {createComparison('Inbound Calls', 'calls', 'inbound')}
                                        {createComparison('Achieved Service Level', 'calls', 'service_level')}
                                        {createComparison('In Hours Inbound Calls', 'calls', 'inbound_in_hours')}
                                        {createComparison('In Hours Achieved Service Level', 'calls', 'service_level_in_hours')}
                                        {createComparison('Completed Calls', 'calls', 'completed')}
                                        {createComparison('Spoke With Person', 'calls', 'spoke_with_person')}
                                        {createComparison('Abandoned Calls', 'calls', 'abandoned')}
                                        {createComparison('Routed Out', 'calls', 'routed_out')}
                                        {createComparison('Voicemail', 'calls', 'voicemail')}
                                        {createComparison('Overflowed Calls', 'calls', 'overflowed')}
                                        {createComparison('Overflowed Queue Calls', 'calls', 'queue_overflowed')}
                                    </tbody>
                                </table>
                            </div>
                        </Card>
                    
                        <Card>
                            <CardHeader>
                                <CardTitle className="mb-0">Support Tickets</CardTitle>
                            </CardHeader>

                            <div className="table-responsive">
                                <table className="table border table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Property</th>
                                            <th>This Month</th>
                                            <th>Last Month</th>
                                            <th className="text-right">Change</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {createComparison('Total Support Tickets', 'supportTickets', 'total')}
                                        {createComparison('Unresolved Tickets', 'supportTickets', 'unresolved')}
                                        {createComparison('Call Tickets', 'supportTickets', 'call')}
                                        {createComparison('Email Tickets', 'supportTickets', 'email')}
                                        {createComparison('Text Tickets', 'supportTickets', 'text')}
                                    </tbody>
                                </table>
                            </div>
                        </Card>
                    
                        <Card>
                            <CardHeader>
                                <CardTitle className="mb-0">eSignatures</CardTitle>
                            </CardHeader>

                            <div className="table-responsive">
                                <table className="table border table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Property</th>
                                            <th>This Month</th>
                                            <th>Last Month</th>
                                            <th className="text-right">Change</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {createComparison('Total Created', 'signingDocuments', 'total')}
                                        {createComparison('Signed', 'signingDocuments', 'signed')}
                                        {createComparison('In Progress', 'signingDocuments', 'in_progress')}
                                        {createComparison('Sent', 'signingDocuments', 'sent')}
                                        {createComparison('Void', 'signingDocuments', 'void')}
                                    </tbody>
                                </table>
                            </div>
                        </Card>

                    </Col>

                    <Col lg={6}>

                        <Card>
                            <CardHeader>
                                <CardTitle className="mb-0">Pipeline Events</CardTitle>
                            </CardHeader>

                            <div className="table-responsive">
                                <table className="table border table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Property</th>
                                            <th>This Month</th>
                                            <th>Last Month</th>
                                            <th className="text-right">Change</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {createComparison('Unique Leads', 'newMatters')}
                                        {createComparison('All Leads', 'leads')}
                                        {createComparison('New Matters', 'uniqueLeads')}
                                    </tbody>
                                </table>
                            </div>
                        </Card>

                        <Card>
                            <CardHeader>
                                <CardTitle className="mb-0">Payments</CardTitle>
                            </CardHeader>

                            <div className="table-responsive">
                                <table className="table border table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Property</th>
                                            <th>This Month</th>
                                            <th>Last Month</th>
                                            <th className="text-right">Change</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {createComparison('Total', 'payments', 'total')}
                                        {createComparison('Succeeded', 'payments', 'succeeded')}
                                        {createComparison('Succeeded Amount', 'payments', 'succeeded_amount', 'currency')}
                                        {createComparison('Failed', 'payments', 'failed')}
                                        {createComparison('Failed Amount', 'payments', 'failed_amount', 'currency')}
                                        {createComparison('Refunded', 'payments', 'refunded')}
                                        {createComparison('Refunded Amount', 'payments', 'refunded_amount', 'currency')}
                                        {createComparison('Partial', 'payments', 'partial')}
                                        {createComparison('Partial Amount', 'payments', 'partial_amount', 'currency')}
                                        {createComparison('Voided', 'payments', 'voided')}
                                        {createComparison('Voided Amount', 'payments', 'voided_amount', 'currency')}
                                        {createComparison('Charged Back', 'payments', 'chargeback')}
                                        {createComparison('Charged Back Amount', 'payments', 'chargeback_amount', 'currency')}
                                        
                                        {createComparison('Recurring Payments', 'payments', 'recurring')}
                                        {createComparison('One Time Payments', 'payments', 'one_time')}
                                        {createComparison('Cash Payments', 'payments', 'cash')}


                                    </tbody>
                                </table>
                            </div>
                        </Card>
                        <Card>
                            <CardHeader>
                                <CardTitle className="mb-0">Time Sensitive</CardTitle>
                            </CardHeader>

                            <div className="table-responsive">
                                <table className="table border table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Property</th>
                                            <th>This Month</th>
                                            <th>Last Month</th>
                                            <th className="text-right">Change</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {createComparison('Tasks', 'tasks', 'total')}
                                        {createComparison('Events', 'events', 'total')}

                                    </tbody>
                                </table>
                            </div>
                        </Card>
                    </Col>

                </Row>

                <div className="pb-6" />
            
            </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    device: state.device,
	    socket: state.socket,
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SupervisorMonthToDate);
