import { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';

import { Card, CardHeader, CardTitle, Col, Row, Progress, CardBody } from "reactstrap";

import ApiError from "components/markup/layout/ApiError";
import Circle from "components/markup/loading/Circle";
import ObjectFinder from 'components/system/Objects/Finder';

import api from 'api';
import formatText from 'utils/formatText';

import A from 'components/markup/links/A'

import { toast } from 'react-toastify'

const CommunicationLiveQueues = ({socket, selected_division, start, end}) => {

    const [ data, setData ] = useState(null);
    const [ err, setErr ] = useState(false)

    const fetchDataInBackground = useCallback(async () => {
        if(start > end) return toast.info(`Start time cannot be less then end time`)
        if((start + ( 86400 * 35 )) < end) return  toast.info(`You must select a range under 31 days.`)

        const _data = await api._supervisor.communication.queues(selected_division._id, start, end)
        if(!_data.data) return setErr(_data.message) 

        let noServiceLevel = _data.data.filter(a => !a.data.service_level)
        let serviceLevel = _data.data.filter(a => a.data.service_level)

        serviceLevel.sort((a, b) => {
            if(a.queue === "All Calls") return -1
            return a.data.serviceLevel < b.data.service_level ? -1 : 1
        })

        setData(serviceLevel.concat(noServiceLevel))
    }, [selected_division._id, start, end])

    const fetchData = useCallback(async () => {
        if(start > end) return toast.info(`Start time cannot be less then end time`)
        if((start + ( 86400 * 35 )) < end) return  toast.info(`You must select a range under 31 days.`)

        setData(null)
        const _data = await api._supervisor.communication.queues(selected_division._id, start, end)
        if(!_data.data) return setErr(_data.message) 

        let noServiceLevel = _data.data.filter(a => !a.data.service_level)
        let serviceLevel = _data.data.filter(a => a.data.service_level)

        serviceLevel.sort((a, b) => {
            if(a.queue === "All Calls") return -1
            return a.data.serviceLevel < b.data.service_level ? -1 : 1
        })

        setData(serviceLevel.concat(noServiceLevel))
    }, [selected_division._id, start, end])

    useEffect(() => {
        fetchData();

        if(selected_division._id) {
            let interval = setInterval(fetchDataInBackground, 15000)

            return () => {
                clearInterval(interval)
            }
        }
       

    }, [fetchData, fetchDataInBackground, selected_division._id])

    if(err) return <ApiError err={err} className="mx-4 py-3" />
    if(!data) return <Circle />


    return (

        <div>
            
            {data.map(d => {

                let averageAbandonTime = parseFloat((d.data.total_time_till_abandoned / d.data.total).toFixed(2))
                let averageConnectedTime = parseFloat((d.data.total_time_till_connected / d.data.total).toFixed(2))

                let inboundPercent   = parseFloat(((d.data.inbound / d.data.total) * 100).toFixed(2))
                let outboundPercent   = parseFloat(((d.data.outbound / d.data.total) * 100).toFixed(2))
                let serviceLevelPercent   = parseFloat(((d.data.service_level / d.data.inbound) * 100).toFixed(2))

                let completedPercent      = parseFloat(((d.data.completed / d.data.total) * 100).toFixed(2))
                let abandonedPercent      = parseFloat(((d.data.abandoned / d.data.total) * 100).toFixed(2))
                let shortAbandonedPercent      = parseFloat(((d.data.short_abandoned / d.data.total) * 100).toFixed(2))
                let voicemailPercent      = parseFloat(((d.data.voicemail / d.data.total) * 100).toFixed(2))
                let routedOutPercent      = parseFloat(((d.data.routed_out / d.data.total) * 100).toFixed(2))
                let inProgressPercent     = parseFloat(((d.data.in_progress / d.data.total) * 100).toFixed(2))
                let failedPercent         = parseFloat(((d.data.failed / d.data.total) * 100).toFixed(2))
                
                let overflowedPercent     = parseFloat(((d.data.overflowed / d.data.total) * 100).toFixed(2))
                let queueOverflowedPercent     = parseFloat(((d.data.queue_overflowed / d.data.total) * 100).toFixed(2))
                
                if(Number.isNaN(inboundPercent)) inboundPercent = '-'
                if(Number.isNaN(outboundPercent)) outboundPercent = '-'
                if(Number.isNaN(serviceLevelPercent)) serviceLevelPercent = '-'
                if(Number.isNaN(completedPercent)) completedPercent = '-'
                if(Number.isNaN(abandonedPercent)) abandonedPercent = '-'
                if(Number.isNaN(shortAbandonedPercent)) shortAbandonedPercent = '-'
                if(Number.isNaN(voicemailPercent)) voicemailPercent = '-'
                if(Number.isNaN(routedOutPercent)) routedOutPercent = '-'
                if(Number.isNaN(inProgressPercent)) inProgressPercent = '-'
                if(Number.isNaN(failedPercent)) failedPercent = '-'
                if(Number.isNaN(overflowedPercent)) overflowedPercent = '-'
                if(Number.isNaN(queueOverflowedPercent)) queueOverflowedPercent = '-'

                let color = !d.data.total ? '' : serviceLevelPercent < 50 ? 'danger' : serviceLevelPercent <= 75 ? 'warning' : serviceLevelPercent <= 90 ? 'info' : 'success';

                return (
                    <Card 
                        key={d._id} 
                        style={{overflow: 'hidden'}}
                        className={`card-color card-primary  table-fixed table-no-stripes z-depth rounde border-${color ? color + ' z-depth-1' : '' } `}
                    >
                        <A href={`/communication/queue/${d._id}`}>
                            <CardHeader className={`bg-${color}`}>
                                <CardTitle className={` mb-0 py-1 display-4 ${color ? 'text-white' : ''}`}>
                                    <small>
                                        <Row>
                                            <Col md={6}>
                                            <b>{d.queue === 'All Calls' ? 'All Calls' : `Queue: ${d.queue}`}</b>
                                            </Col>
                                            <Col md={6} className="text-right">
                                                Total Calls: {d.data.total}
                                            </Col>
                                        </Row>
                                    </small>
                                </CardTitle>
                                {d.division ? <p className={`mb-0 mt--3 ${color ? 'text-white' : ''}`}><ObjectFinder collection="divisions" _id={d.division} /></p> : ''}
                            </CardHeader>
                        </A>

                        <CardBody>
                            <div className="progress-wrapper mt-0 pt-0">
                                <div className="progress-info mb-2 mt-0">
                                    <div className="progress-label mb-0">
                                        <span className="py-1">Service Level</span>
                                    </div>
                                    <div className="progress-percentage">
                                        <span className="text-sm">{serviceLevelPercent === '-' ? 0 : serviceLevelPercent}%</span>
                                    </div>
                                </div>
                                <Progress max="100" value={serviceLevelPercent === '-' ? 0 : serviceLevelPercent} color={color ? color : 'default'} className="mb-2" />
                            </div>
                        </CardBody>

                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={{width: 200}}>Metrics</th>
                                        <th className="text-right">Total</th>
                                        <th className="text-right">%</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>Inbound Service Level</td>
                                        <td className="text-right">{d.data.service_level}</td>
                                        <td className="text-right">{serviceLevelPercent === '-' ? 0 : serviceLevelPercent.toFixed(2)}%</td>
                                    </tr>

                                    <tr>
                                        <td>Calls Inbound</td>
                                        <td className="text-right">{d.data.inbound}</td>
                                        <td className="text-right">{inboundPercent === '-' ? 0 : inboundPercent.toFixed(2)}%</td>
                                    </tr>
                                    <tr>
                                        <td>Calls Outbound</td>
                                        <td className="text-right">{d.data.outbound}</td>
                                        <td className="text-right">{outboundPercent === '-' ? 0 : outboundPercent.toFixed(2)}%</td>
                                    </tr>
                                    <tr>
                                        <td>Calls Completed</td>
                                        <td className="text-right">{d.data.completed}</td>
                                        <td className="text-right">{completedPercent === '-' ? 0 : completedPercent.toFixed(2)}%</td>
                                    </tr>

                                    <tr>
                                        <td>Calls Abandoned (Long)</td>
                                        <td className="text-right">{d.data.abandoned}</td>
                                        <td className="text-right">{abandonedPercent === '-' ? 0 : abandonedPercent.toFixed(2)}%</td>
                                    </tr>
                                    <tr>
                                        <td>Calls Abandoned (Short)</td>
                                        <td className="text-right">{d.data.short_abandoned}</td>
                                        <td className="text-right">{abandonedPercent === '-' ? 0 : abandonedPercent.toFixed(2)}%</td>
                                    </tr>

                                    <tr>
                                        <td>Calls Went To Voicemail</td>
                                        <td className="text-right">{d.data.voicemail}</td>
                                        <td className="text-right">{voicemailPercent === '-' ? 0 : voicemailPercent.toFixed(2)}%</td>
                                    </tr>

                                    <tr>
                                        <td>Calls Routed Out</td>
                                        <td className="text-right">{d.data.routed_out}</td>
                                        <td className="text-right">{routedOutPercent === '-' ? 0 : routedOutPercent.toFixed(2)}%</td>
                                    </tr>

                                    <tr>
                                        <td>Calls In Progress</td>
                                        <td className="text-right">{d.data.in_progress}</td>
                                        <td className="text-right">{inProgressPercent === '-' ? 0 : inProgressPercent.toFixed(2)}%</td>
                                    </tr>
                                    <tr>
                                        <td>Calls Failed</td>
                                        <td className="text-right">{d.data.failed}</td>
                                        <td className="text-right">{failedPercent === '-' ? 0 : failedPercent.toFixed(2)}%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={{width: 200}}>Overflows</th>
                                        <th className="text-right">Total</th>
                                        <th className="text-right">%</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>Calls Overflowed</td>
                                        <td className="text-right">{d.data.overflowed}</td>
                                        <td className="text-right">{overflowedPercent === '-' ? 0 : overflowedPercent.toFixed(2)}%</td>
                                    </tr>
                                    <tr>
                                        <td>Queues Overflowed</td>
                                        <td className="text-right">{d.data.queue_overflowed}</td>
                                        <td className="text-right">{queueOverflowedPercent === '-' ? 0 : overflowedPercent.toFixed(2)}%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={{width: 200}}>Timelines</th>
                                        <th className="text-right">%</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>Avg. Time Till Abandoned</td>
                                        <td className="text-right">{formatText(averageAbandonTime).secondsToTime()}</td>
                                    </tr>

                                    <tr>
                                        <td>Avg. Time Till Connected</td>
                                        <td className="text-right">{formatText(averageConnectedTime).secondsToTime()}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        {/* <CardFooter className="bg-se">
                            <p className="text-sm mb-0">{} Total</p>
                        </CardFooter>  */}
                    </Card>
                )
            })}

        </div>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	    viewing_user: state.auth.viewing_user,
        socket: state.socket,
	};
};

export default connect(mapStateToProps, '')(CommunicationLiveQueues);
