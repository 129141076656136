import { useCallback, useEffect, useState } from "react";
import { Card, CardHeader, CardTitle, Container } from "reactstrap";

import Circle from 'components/markup/loading/Circle';

import moment from 'moment';
import formatText from 'utils/formatText'

const HistoricalDailyHandleTimes = ({ handleTimes, day }) => {

    const [data, setData] = useState(null)

    const getColorOfChange = useCallback((num, isSeconds) => {
        if(num === 0 || num === '-') return '-'
        return num > 0 ? <b className="text-success">+{num} Seconds</b> : <b className="text-danger">-{num} Seconds</b>
    }, [])

    useEffect(() => {

        const getAverage = (num, total) => {
            if(!num || !total) return 0;

            return parseFloat(((num / total)).toFixed(2))
        }
        const getChange = (num1, num2) => {
            return parseFloat((num1 - num2).toFixed(2))
        }

        const queues = handleTimes.today;
        const handleMetrics = []

        queues.forEach(q => {
            const queueLastDayOfWeek = handleTimes.lastDayOfWeek.find(d => d._id === q._id)
            const queueCurrentWeek = handleTimes.currentWeek.find(d => d._id === q._id)
            const queueDays90 = handleTimes.days90.find(d => d._id === q._id)

            const time = getAverage(q.data.total_duration, q.data.total)
            const timeLastDayOfWeek = getAverage(queueLastDayOfWeek.data.total_duration, queueLastDayOfWeek.data.total)
            const timeCurrentWeek = getAverage(queueCurrentWeek.data.total_duration, queueCurrentWeek.data.total)
            const time90Days = getAverage(queueDays90.data.total_duration, queueDays90.data.total)

            const changeLastDayOfWeek = getChange(timeLastDayOfWeek, time)
            const changeCurrentWeek = getChange(timeCurrentWeek, time)
            const change90Days = getChange(time90Days, time)

            handleMetrics.push({
                _id: q._id,
                queue: q.queue,
                time,
                timeLastDayOfWeek,
                timeCurrentWeek,
                time90Days,
                changeLastDayOfWeek,
                changeCurrentWeek,
                change90Days,
            })

        })

        setData({
            handleMetrics
        })

    }, [handleTimes])

    if(!data) return <Circle />

    return (

        <>

            <Container className="mt-5" fluid>
                <h2 className=" text-uppercase">Handle Times</h2>
                
                {data.handleMetrics.map((q, i) => (
                    <Card key={q._id} className="mb-0" style={i !== 0 ? {borderTop: 'none'} : {}}>
                        <CardHeader > 
                            <CardTitle className="mb-0">{q.queue}</CardTitle>
                        </CardHeader>

                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Handle Time</th>
                                    <th>Handle Time Last {moment(day).format('dddd')}</th>
                                    <th>Handle Time This Week</th>
                                    <th>Handle Time Last 90 Days</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>
                                        {q.time} Seconds
                                        <div>( {formatText(q.time).secondsToTime(null, true)} )</div>
                                    </td>
                                    <td>
                                        {q.timeLastDayOfWeek} Seconds
                                        <div>( {formatText(q.timeLastDayOfWeek).secondsToTime(null, true)} )</div>
                                    </td>
                                    <td>
                                        {q.timeCurrentWeek} Seconds
                                        <div>( {formatText(q.timeCurrentWeek).secondsToTime(null, true)} )</div>
                                    </td>
                                    <td>
                                        {q.time90Days} Seconds
                                        <div>( {formatText(q.time90Days).secondsToTime(null, true)} )</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>-</td>
                                    <td>
                                        {getColorOfChange(q.changeLastDayOfWeek, true)}
                                        <div>( {formatText(q.changeLastDayOfWeek).secondsToTime(null, true)} )</div>
                                    </td>
                                    <td>
                                        {getColorOfChange(q.changeCurrentWeek, true)}
                                        <div>( {formatText(q.changeCurrentWeek).secondsToTime(null, true)} )</div>
                                    </td>
                                    <td>
                                        {getColorOfChange(q.change90Days, true)}
                                        <div>( {formatText(q.change90Days).secondsToTime(null, true)} )</div>
                                    </td>
                                </tr>
                              
                            </tbody>
                            
                        </table>
                    </Card>
                ))}

            </Container>

        </>
    )

}

export default HistoricalDailyHandleTimes