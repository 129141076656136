import reactHTMLParser from 'html-react-parser'

function formatString (string) {

    this.string = string

}

// capitalize each word in a string
formatString.prototype.capitalize = function () {

    if(!this.string) return undefined
    const split = this.string.split(' ');
    let finalString;

    try {
        split.forEach(s => {
            if(finalString) {
                finalString += ' ' + s.replace(/^./, s[0].toUpperCase())
            } else {
                finalString = s.replace(/^./, s[0].toUpperCase())
            }
        })
    } catch(e) {
        return this.string;
    }
    
    return finalString
}

// shortens an email for example from johnmaher@architeck.io to jo..@architeck.io
formatString.prototype.trimEmail = function (maxLength) {

    if(!maxLength) maxLength = 20;

    const email = this.string;
    if(!email) return email;
    if(!email.includes('@')) return email;
    if(email.length <= maxLength) return email;

    const split = email.split('@')
    const address = split[0];
    const domain = split[1];

    if(!address || !domain) return email;



    if(domain.length >= maxLength) {
        return address.slice(0, 1) + '..@' + domain;
    }

    let remainingLength = maxLength - domain.length + 1;
    return address.slice(0, remainingLength) + '..@' + domain;
}


formatString.prototype.phone = function () {

    const phone = this.string;

    if(!phone) return undefined
    if(phone.length !== 12) return phone

    let string1 = phone.slice(2,5)
    let string2 = phone.slice(5, 8)
    let string3 = phone.slice(8, 12)

    return `(${string1}) ${string2}-${string3}`

}

/*
Documentation

strips html from a string

*/

formatString.prototype.stripHTML = function(maxLength) {

    const html = this.string;
    if(!html) return undefined

    var tmp = document.implementation.createHTMLDocument("New").body;
    tmp.innerHTML = html;

    let text = tmp.textContent || tmp.innerText || "";
    
    return text && maxLength && text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
}

//returns clickable links
formatString.prototype.smartFormatting = function() {

    let str = this.string;
    if(!str) return undefined

    const linkRegex = /(https?:\/\/)?(www\.)?[^\s]+\.[^\s]+/g

    function replacer(matched) {
        let withProtocol = matched
        
        if(!withProtocol.startsWith("http")) {
          withProtocol = "http://" + matched
        }
       
        const newStr = `<a target="_blank" class="text-underline" rel="noopener noreferrer" href="${withProtocol}">
          ${matched}
        </a>`
        
        return newStr
    }

    str = str.replace(linkRegex, replacer)

    return reactHTMLParser(str)
}

formatString.prototype.withCommas = function () {
    return this.string ? this.string.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : this.string
}

formatString.prototype.secondsToTime = function (hoursOnly, showWithSeconds) {
    let number = this.string ? parseInt(this.string) : 0
    if(number < 0) number = number * -1

    const day       = 86400;
    const hour      = 3600;
    const minute    = 60;
    const second    = 1;

    if(number === 0) return '0 Seconds'
    if(!number || number === 'N/A') return 'N/A'


    let days = Math.floor(number / day);
    let hours = Math.floor((number - (days * day)) / hour);
    let minutes = Math.floor((number - (days * day) - (hours * hour)) / minute);
    let seconds = Math.floor((number - (days * day) - (hours * hour)  - (minutes * minute)) / second);

    if(days !== 0) {
        if(hoursOnly) {
            hours += days * 24
            return  hours + (hours === 1 ? ' Hr ' : ' Hrs ').toString() + minutes + (minutes === 1 ? ' Min ' : ' Mins ').toString();

        }
        return days + (days === 1 ? ' Day ' : ' Days ').toString() + hours + (hours === 1 ? ' Hr ' : ' Hrs ').toString() + minutes + (minutes === 1 ? ' Min ' : ' Mins ').toString();
    }
    if(hours !== 0) return hours + (hours === 1 ? ' Hr ' : ' Hrs ').toString() + minutes + (minutes === 1 ? ' Min ' : ' Mins ').toString();


    if(minutes !== 0) return showWithSeconds ? 
        minutes + (minutes === 1 ? ' Min ' : ' Mins ').toString() + ' ' + seconds + ' Seconds' : 
        minutes + (minutes === 1 ? ' Min ' : ' Mins ').toString();
        
    if(seconds !== 0) return seconds + (seconds === 1 ? ' Second ' : ' Seconds ').toString();
    
    return 'N/A';
}

formatString.prototype.mimetype = function () {
    let mimetype = this.string;
    if(!mimetype) return 'unknown';
    
    mimetype = mimetype.replace(/application\//g, '').replace(/image\//g, '');
    if(mimetype === 'vnd.openxmlformats-officedocument.wordprocessingml.document') return 'word'

    return mimetype;

}

formatString.prototype.contactIdentifier = function (contact) {
   if(contact.display_name) return contact.display_name;
   if(contact.email) return contact.email;
   if(contact.phone) return contact.phone;
    return ''
}

const exports = (string) => new formatString(string)
export default exports;