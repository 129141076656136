import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import moment from 'moment';
import { Card, Col, Container, Row } from "reactstrap";

import A from 'components/markup/links/A';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';

import ApiError from "components/markup/layout/ApiError";
import Circle from 'components/markup/loading/Circle';
import ObjectFinder from 'components/system/Objects/Finder';

import api from 'api';

import DatePicker from 'react-datepicker';

import formatText from 'utils/formatText';

const SupervisorEvents = ({selected_division, DOMAINS}) => {

    const [err, setErr] = useState(false)
    const [data, setData] = useState(null)
    const [start, setStart] = useState(moment().startOf('day').toDate())

    const fetchData = useCallback(async () => {
        setData(null)
        const result = await api._supervisor.taskCalls(selected_division._id, { start: parseInt(moment(start).format('X')) })
        if(!result.success) return setErr(result.message)
        setData(result.data)

    }, [selected_division._id, start])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(err) return <ApiError err={err} className="mx-4 py-3" />

    const title = 'Task Calls'

    return (

        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            description="All Tasks"
            leftColSize={3}
            actionComponent={(
                <Row>

                    <Col md={9}>

                    </Col>
                   
                
                    <Col md={3}>
                        <p className="text-sm mb-0">Select Day</p>
                        <DatePicker 
                            selected={start} 
                            onChange={(date) => setStart(date)} 
                        />
                    </Col>

                
                </Row>
            )}
        />

        <Container fluid>


    
            <Card className="card-color card-primary table-fixed table-no-stripes position-relative">
                {!data ? <Circle /> : (
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Task</th>
                                    <th>Matter</th>
                                    <th>Users</th>
                                    <th>Due</th>
                                    <th>Calls</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length ? data.map(d => (
                                    <tr key={d._id}>
                                        <td><A href={`${DOMAINS.APP}/matters/${d.matter}?tab=Events`}>{d.finished_at ? <i className="fas fa-check text-success mr-2" /> : <i className="fas fa-times mr -2 text-danger" />} {d.name}</A></td>
                                        <td><A href={`${DOMAINS.APP}/matters/${d.matter}?tab=Events`}><ObjectFinder collection="matters" _id={d.matter} /></A></td>
                                        <td>
                                            {d.assigned_to.map((u, i) => (
                                                <span key={i}>{i !== 0 ? ', ' : ''} <ObjectFinder collection="users" _id={u} /> </span>
                                            ))}
                                        </td>
                                        <td>{moment.unix(d.due_date).format('h:mm A')}</td>
                                        <td>
                                            {d.calls.map(c => {

                                                let color = 'success'
                                                if((c.start - d.due_date) > 900) color = 'danger'
                                                if((c.start - d.due_date) < 900) color = 'danger'

                                                return !c.duration ? '' : (
                                                    <A key={c._id} href={`/calls/${c._id}`}>
                                                        <div  >
                                                            {c.direction === 'inbound' ?(
                                                                <i className="fas fa-arrow-down text-warning mr-2" />
                                                            ) : (
                                                                <i className="fas fa-arrow-up text-success mr-2" />
                                                            )}
                                                            { ' ' }
                                                            {formatText(c.duration).secondsToTime()}
                                                            { ' - '}
                                                            <span className={`text-${color}`}> {moment.unix(c.start).format('h:mm A')}</span>
                                                            
                                                        </div>
                                                    </A>
                                                )
                                            })}
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td>No Events Found</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>
                )}
               
            </Card>

        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
        DOMAINS: state.load.DOMAINS,
	};
};

export default connect(mapStateToProps, '')(SupervisorEvents);
