import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import moment from 'moment-timezone';
import { Col, Container, Row } from "reactstrap";

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import Circle from 'components/markup/loading/Circle';
import BarLine from 'components/charts/BarLine';
import DatePicker from 'react-datepicker'

import api from 'api';

import { toast } from 'react-toastify';

const MattersAll = ({selected_division, match}) => {

    const [ data, setData ] = useState(null)
    const [day, setDay] = useState(new Date())

    const fetchData = useCallback(async () => {

        const startTime = parseInt(moment(day).tz(selected_division.timezone).startOf('day').format('X'))

        const filter = { 
            start: startTime,
            dayOfWeek: moment(day).format('dddd')
        }

        setData(null);

        const result = await api._supervisor.communication.callQueue(selected_division._id, match.params.call_queue, filter )
        if(!result.data) return toast.error(`Something went wrong, please refresh your page and try again.`)

        setData(result.data)
    }, [day, match.params.call_queue, selected_division._id, selected_division.timezone])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(!data) return <div className="py-6"><Circle /></div>

    return (

        <>

        <Helmet>
            <title>{data.name} - Queue Analytics</title>
            <meta name="description" content={data.name} />
        </Helmet>

        <HeaderNavigation 
            title={<span>{data.name} - Queue Analytics</span>}
            description={
                <span>
                    Selected Week: {' '}
                    {
                        data.startOfThisWeek ? 
                        // take server values for accuracy
                        <span>{moment.unix(data.startOfThisWeek).format('MM/DD') } - {moment.unix(data.endOfThisWeek).format('MM/DD') }</span> :
                        // fallback to date selected in browser
                        <span>{moment(day).startOf('week').format('MM/DD')} - {moment(day).endOf('week').format('MM/DD')}</span>
                    }
                </span>
            }
            actionComponent={(
                <div>
                    <p className="text-sm mb-0">Select Week</p>
                    <DatePicker 
                        selected={day} 
                        onChange={(date) => setDay(date)} 
                    />
                </div>
            )}
        />

        <Container fluid>

            <Row>
                <Col lg={6}>
                    <BarLine 
                        type="line"
                        title="Calls By Day"
                        classNames={'border bg-white mb-4'}
                        labels={data.callsByDay.labels}
                        datasets={data.callsByDay.datasets}
                    />

                    <BarLine 
                        type="line"
                        title="Sunday - Calls Per Hour"
                        classNames={'border bg-white mb-4'}
                        labels={data.callsByHour.sun.labels}
                        datasets={data.callsByHour.sun.datasets}
                    />
                    
                    <BarLine 
                        type="line"
                        title="Monday - Calls Per Hour"
                        classNames={'border bg-white mb-4'}
                        labels={data.callsByHour.mon.labels}
                        datasets={data.callsByHour.mon.datasets}
                    />
                    <BarLine 
                        type="line"
                        title="Tuesday - Calls Per Hour"
                        classNames={'border bg-white mb-4'}
                        labels={data.callsByHour.tue.labels}
                        datasets={data.callsByHour.tue.datasets}
                    />
                    <BarLine 
                        type="line"
                        title="Wednesday - Calls Per Hour"
                        classNames={'border bg-white mb-4'}
                        labels={data.callsByHour.wed.labels}
                        datasets={data.callsByHour.wed.datasets}
                    />
                    <BarLine 
                        type="line"
                        title="Thursday - Calls Per Hour"
                        classNames={'border bg-white mb-4'}
                        labels={data.callsByHour.thu.labels}
                        datasets={data.callsByHour.thu.datasets}
                    />
                    <BarLine 
                        type="line"
                        title="Friday - Calls Per Hour"
                        classNames={'border bg-white mb-4'}
                        labels={data.callsByHour.fri.labels}
                        datasets={data.callsByHour.fri.datasets}
                    />
                    <BarLine 
                        type="line"
                        title="Saturday - Calls Per Hour"
                        classNames={'border bg-white mb-4'}
                        labels={data.callsByHour.sat.labels}
                        datasets={data.callsByHour.fri.datasets}
                    />
                </Col>

                <Col lg={6}>
                    <BarLine 
                        type="line"
                        title="Service Level By Day"
                        classNames={'border bg-white mb-4'}
                        labels={data.serviceLevelByDay.labels}
                        datasets={data.serviceLevelByDay.datasets}
                    />

                    <BarLine 
                        type="line"
                        title="Sunday - Service Level Per Hour"
                        classNames={'border bg-white mb-4'}
                        labels={data.serviceLevelByHour.sun.labels}
                        datasets={data.serviceLevelByHour.sun.datasets}
                    />
                    
                    <BarLine 
                        type="line"
                        title="Monday - Service Level Per Hour"
                        classNames={'border bg-white mb-4'}
                        labels={data.serviceLevelByHour.mon.labels}
                        datasets={data.serviceLevelByHour.mon.datasets}
                    />
                    <BarLine 
                        type="line"
                        title="Tuesday - Service Level Per Hour"
                        classNames={'border bg-white mb-4'}
                        labels={data.serviceLevelByHour.tue.labels}
                        datasets={data.serviceLevelByHour.tue.datasets}
                    />
                    <BarLine 
                        type="line"
                        title="Wednesday - Service Level Per Hour"
                        classNames={'border bg-white mb-4'}
                        labels={data.serviceLevelByHour.wed.labels}
                        datasets={data.serviceLevelByHour.wed.datasets}
                    />
                    <BarLine 
                        type="line"
                        title="Thursday - Service Level Per Hour"
                        classNames={'border bg-white mb-4'}
                        labels={data.serviceLevelByHour.thu.labels}
                        datasets={data.serviceLevelByHour.thu.datasets}
                    />
                    <BarLine 
                        type="line"
                        title="Friday - Service Level Per Hour"
                        classNames={'border bg-white mb-4'}
                        labels={data.serviceLevelByHour.fri.labels}
                        datasets={data.serviceLevelByHour.fri.datasets}
                    />
                    <BarLine 
                        type="line"
                        title="Saturday - Service Level Per Hour"
                        classNames={'border bg-white mb-4'}
                        labels={data.serviceLevelByHour.sat.labels}
                        datasets={data.serviceLevelByHour.fri.datasets}
                    />
                </Col>
            </Row>

          
          
{/*     
            <Card className="card-color card-primary table-fixed">
                {loading ? <Circle /> : (
                    <Table
                    hideSearch={true}
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search Status..."
                    query={query}
                    columns={columns}
                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
                )}
            </Card> */}

        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
        DOMAINS: state.load.DOMAINS,
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(MattersAll);
